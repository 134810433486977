/************************************** staff-top  ****************************** */

.l-staff-top {
	position: relative;
	background: url(../images/park/service/service_menu_bg_pc.png) top center;
	padding: 100px 0px 100px 0px;
	width: 100%;
	max-width: 100%;
	// min-height: 100vh
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -18vh;

	.staff-menu {
		width: 1050px;
		max-width: 100%;
		padding: 10px;
		margin: 100px auto 0; }

	.c-title {
		color: #54A868;
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 50px; }

	.c-help {
		font-size: 16px;
		line-height: 2;
		margin-bottom: 50px;
		@media screen and (max-width: 767px) {
			text-align: left; } }

	.menu-item {
		background-color: white;
		border: 2px solid #54A868;
		border-radius: 10px;
		width: 22%;
		height: 65px;
		box-shadow: 3px 3px #54A868;
		float: left;
		margin: 20px 2% 0px 1%;

		&:hover {
			transform: scale(1.1);
			transform-origin: center;
			transition: all .3s; }

		a {
			position: relative;
			padding: 0.8em 1em 1em 1em;
			text-decoration: none !important;
			font-size: 18px;
			display: block;
			color: #000000;

			p {
				padding-left: 15%;
				padding-top: 4px;
				font-size: 18px; }

			&:after {
				position: absolute;
				right: 15%;
				top: 22%;
				content: url('../images/park/staff/arrow_down.png'); } } } }

/************************************** staff-doctor  ****************************** */

.l-staff-doctor {
	margin: 100px 0px 50px;
	position: relative;
	@media screen and (max-width: 767px) {
		margin: 50px 0px; }

	.c-title {
		color: #54A868;
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		letter-spacing: 5px; }

	.c-title-jp {
		color: #543C30;
		font-size: 16px; }

	#boss-history {
		width: 1150px;
		max-width: 100%;
		margin: 50px auto 0px;
		padding: 20px;
		@media screen and (max-width: 767px) {
			margin: 0 auto; }

		.photo-image {
			position: relative;
			box-shadow: -20px 20px 2px 1px #FFECC8;
			border-radius: 10px;
			max-width: 100%;
			z-index: 5; }

		.c-subtitle {
			font-size: 26px;
			color: #FF8266;
			line-height: 1.7;
			@media screen and (max-width: 767px) {
				margin-top: 30px;
				line-height: 1.4; } }
		.c-help {
			font-size: 16px; }

		.c-title-career {
			font-size: 18px;
			font-weight: bold;
			color: #54A868;
			background-color: white;
			padding: 10px 20px; }

		.l-career-rect {
			background-color: white;
			border-radius: 10px;
			border: 2px solid #54A868;
			margin-top: -30px; }

		.c-title {
			color: #54A868;
			font-size: 18px; }

		.c-help {
			font-size: 16px;
			line-height: 2; }

		table {
			th, td {
				padding: 10px 10px 2px 10px;
				font-size: 16px;
				text-align: left;
				position: relative;
				&.is-span {
					padding-left: 30%;
					span {
						position: absolute;
						left: 0;
						height: 100%;
						display: block;
						width: 30%;
						font-weight: bold; } } } } }
	.staff-deco1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 35%; }

	.staff-deco2 {
		position: absolute;
		top: 0;
		right: 0;
		width: 35%; }

	.c-factor {
		margin-top: 0px; }

	.c-factor-print {
		margin-top: 20px; } }

/************************************** staff  ****************************** */

.l-staff-staff {
	margin: 100px 0px 20px 0px;
	position: relative;

	.c-title {
		color: #54A868;
		font-size: 48px;
		font-family: 'Fredoka One', cursive; }

	.c-title-jp {
		color: #543C30;
		font-size: 16px; }

	.staff-deco1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 35%; }

	.staff-deco2 {
		position: absolute;
		top: 0;
		right: 0;
		width: 35%; }

	#staff-list {
		width: 900px;
		margin: 100px auto;
		padding: 20px;
		max-width: 100%; }

	.item {
		width: 100%;
		border: 2px solid #54A868;
		border-radius: 10px;
		padding: 20px;
		margin-bottom: 30px;

		.item-job {
			color: white;
			background-color: #FF8266;
			border-radius: 30px;
			padding: 2px 40px; }

		.item-name {
			font-size: 22px; }

		.item-help {
			font-size: 16px;
			line-height: 2; } } }

#staff-list .item img {
	border-radius: 20px;
	margin-bottom: 10px; }

/************************************** staff-recruit  ****************************** */

.l-staff-recruit {
	position: relative;
	background: url(../images/park/staff/staff_recruit_bg.png) top center;
	padding: 100px 0px 200px 0px;
	width: 100%;
	max-width: 100%;
	min-height: 100vh;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.c-title {
		color: #54A868;
		font-size: 48px;
		font-family: 'Fredoka One', cursive; }

	.c-title-jp {
		color: #543C30;
		font-size: 16px; }

	#recruit_table {
		width: 900px;
		max-width: 100%;
		padding: 10px;
		margin: 40px auto;
		background-color: #fff8eb;

		table {
			width: 90%;
			margin-left: 5%;

			tr {
				border-bottom: 2px dotted #FFD6AE; }

			th {
				width: 30%;
				vertical-align: middle;
				text-align: center;
				padding: 25px 0px;
				font-size: 16px;
				font-weight: normal;
				color: #54A868; }

			td {
				width: 70%;
				vertical-align: middle;
				padding: 25px 0px;
				font-size: 16px; } } }
	& + .l-footer-top {
		margin-top: -15vh; } }
#boss-history .c-subbox {
	padding: 0px 20px; }

@media screen and (max-width: 767px) {
	.l-staff-top {
		background: url(../images/park/staff/staff_bg_sp.png) no-repeat;
		background-size: 100% 100%;
		margin-top: -20vh;
		min-height: 100%;

		.staff-menu {
			margin-top: 0;

			.menu-item {
				width: 45%;
				margin: 20px 3% 0px 2%; } }

		.menu-item a p {
			padding-left: 0;
			font-size: 15px; } }

	.l-staff-recruit {
		background: url(../images/park/staff/staff_recruit_bg_sp.png) no-repeat;
		background-size: 100% 100%;
		min-height: 100%;
		padding: 30px 0px 160px; }

	.l-staff-top .menu-item a p {
		font-size: 14px; }

	.l-staff-doctor .c-title {
		margin-top: 50px; }

	#boss-history .c-subbox {
		padding: 0 !important; }

	.l-staff-doctor {
		#boss-history {
			.photo-image {
				box-shadow: -10px 10px 2px 1px #FFECC8; }

			.c-title {
				margin-top: 20px !important; } }

		.c-factor {
			margin-top: 20px; }

		.c-factor-print {
			margin-top: 0px; } }

	.l-staff-staff {
		margin-top: 20px !important;

		.c-title {
			margin-top: 50px; }

		#staff-list {
			margin: 10px 0px !important; } }

	.l-staff-recruit #recruit_table {
		margin: 0px !important;

		td, th {
			padding: 25px 10px !important; } }

	.l-staff-doctor .c-title, .l-staff-staff .c-title, .l-staff-recruit .c-title {
		font-size: 32px; } }

@media screen and (min-width: 768px) and (max-width: 950px) {
	.l-staff-top {
		.staff-menu {
			margin-top: -50px; }

		.menu-item a p {
			padding-left: 0;
			font-size: 15px; } }

	.l-staff-doctor #boss-history .photo-image {
		box-shadow: -10px 10px 2px 1px #FFECC8; } }
