// ---------------------------------------------------------
// CSSのみでアイコンを再現するmixinです。
// ---------------------------------------------------------
// 矢印（>）
// @include icoArrow(before or after, top, right, bottom, left, 大きさ, 線の幅, 色, 上-45deg or 右45deg or 下135deg or 左-135deg);
// +icoArrow(before or after, top, right, bottom, left, 大きさ, 線の幅, 色, 上-45deg or 右45deg or 下135deg or 左-135deg);
// ---------------------------------------------------------
// 三角形（▶）
// @include icoTriangle01(before or after, top, right, bottom, left, top or right or bottom or left, 幅, 高さ, 色);
// +icoTriangle01(before or after, top, right, bottom, left, top or right or bottom or left, 幅, 高さ, 色);
// ---------------------------------------------------------
// 直角三角形
// @include icoTriangle02(before or after, top, right, bottom, left, topLeft or topRight or bottomLeft or bottomRight, 幅, 高さ, 色);
// +icoTriangle02(before or after, top, right, bottom, left, topLeft or topRight or bottomLeft or bottomRight, 幅, 高さ, 色);
// ---------------------------------------------------------
// 丸（●）
// @include icoCircle(before or after, top, right, bottom, left, 大きさ, 色);
// +icoCircle(before or after, top, right, bottom, left, 大きさ, 色);
// ---------------------------------------------------------
// プラス（＋）、マイナス（－）、バツ（✕）
// @include icoSymbol(plus or minus or cross, top, right, bottom, left, 大きさ, 線の幅, 色);
// +icoSymbol(plus or minus or cross, top, right, bottom, left, 大きさ, 線の幅, 色);
// ---------------------------------------------------------
// メニュー（三）
// @include icoMenu(外枠の幅, 外枠の高さ, top, right, bottom, left, 線の幅, 線の高さ, 角丸, 線の色, 線の間隔, ラベル文言);
// +icoMenu(外枠の幅, 外枠の高さ, top, right, bottom, left, 線の幅, 線の高さ, 角丸, 線の色, 線の間隔, ラベル文言);
// ---------------------------------------------------------
// 画像でアイコン挿入
// @include icoImage(before or after, top, right, bottom, left, 幅, 高さ, "パス");
// +icoImage(before or after, top, right, bottom, left, 幅, 高さ, "パス");
// ---------------------------------------------------------
// アイコンフォントインライン配置
// @include iconFontInline(before or after, フォント名, サイズ, 色, 左右マージン);
// +iconFontInline(before or after, フォント名, サイズ, 色, 左右マージン);
// ---------------------------------------------------------
// アイコンフォント絶対配置
// @include iconFontAbsolute(before or after, フォント名, top, right, bottom, left, サイズ, 色);
// +iconFontAbsolute(before or after, フォント名, top, right, bottom, left, サイズ, 色);
// ---------------------------------------------------------


// 矢印（>）
// @include icoArrow(before or after, top, right, bottom, left, 大きさ, 線の幅, 色, 上-45deg or 右45deg or 下135deg or 左-135deg);
@mixin icoArrow(
  $before-or-after,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $arrowSize: 14px,
  $arrowWidth: 1px,
  $arrowColor: #999,
  $arrowDeg: 45deg
  ) {
  position: relative;
  &:#{$before-or-after} {
    content: "";
    display: block;
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $arrowSize;
    height: $arrowSize;
    background-color: transparent;
    @if $arrowDeg == -45deg or $arrowDeg == 135deg {
      @if $right == 50% {
        margin-right: $arrowSize / 2 *(-1);
      }
      @if $left == 50% {
        margin-left: $arrowSize / 2 *(-1);
      }
    }
    @if $arrowDeg == -45deg {
      @if $top == 50% {
        margin-top: ($arrowSize / 2) /2 *(-1);
      }
      @if $bottom == 50% {
        margin-bottom: (($arrowSize / 2) /2 + ($arrowSize / 2)) *(-1);
      }
    }
    @if $arrowDeg == 135deg {
      @if $top == 50% {
        margin-top: (($arrowSize / 2) /2 + ($arrowSize / 2)) *(-1);
      }
      @if $bottom == 50% {
        margin-bottom: ($arrowSize / 2) /2 *(-1);
      }
    }
    @if $arrowDeg == 45deg or $arrowDeg == -135deg {
      @if $top == 50% {
        margin-top: $arrowSize / 2 *(-1);
      }
      @if $bottom == 50% {
        margin-bottom: $arrowSize / 2 *(-1);
      }
    }
    @if $arrowDeg == 45deg {
      @if $right == 50% {
        margin-right: ($arrowSize / 2) /2 - ($arrowSize / 2);
      }
      @if $left == 50% {
        margin-left: (($arrowSize / 2) /2 + ($arrowSize / 2)) *(-1);
      }
    }
    @if $arrowDeg == -135deg {
      @if $right == 50% {
        margin-right: (($arrowSize / 2) /2 + ($arrowSize / 2)) * (-1);
      }
      @if $left == 50% {
        margin-left: ($arrowSize / 2) /2 - ($arrowSize / 2);
      }
    }
    border-top: solid $arrowWidth $arrowColor;
    border-right: solid $arrowWidth $arrowColor;
    transform: rotate($arrowDeg);
  }
}

// 三角形（▶）
// @include icoTriangle01(before or after, top, right, bottom, left, top or right or bottom or left, 幅, 高さ, 色);
@mixin icoTriangle01 (
  $before-or-after,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $direction: null,
  $width: 50px,
  $height: 50px,
  $color: #999
  ) {
  position: relative;
  &:#{$before-or-after} {
    content: "";
    display: block;
    position: absolute;
    width: $width;
    height: $height;
    @if $top == 50% or $bottom == 50% or $right == 50% or $left == 50% {
      margin: auto;
    }
    @if $top == 50% or $bottom == 50% {
      top: 0;
      bottom: 0;
    } @else {
      top: $top;
      bottom: $bottom;
    }
    @if $right == 50% or $left == 50% {
      right: 0;
      left: 0;
    } @else {
      right: $right;
      left: $left;
    }
    @if $direction == top {
      background:
      linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/50% 100%,
      linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top right/50% 100%;
    }
    @if $direction == bottom {
      background:
      linear-gradient(to top right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/50% 100%,
      linear-gradient(to top left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top right/50% 100%;
    }
    @if $direction == right {
      background:
      linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 50%,
      linear-gradient(to top left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat bottom right/100% 50%;
    }
    @if $direction == left {
      background:
      linear-gradient(to top right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat bottom left/100% 50%,
      linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top right/100% 50%;
    }
  }
}

// 直角三角形
// @include icoTriangle02(before or after, top, right, bottom, left, topLeft or topRight or bottomLeft or bottomRight, 幅, 高さ, 色);
@mixin icoTriangle02 (
  $before-or-after,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $direction: null,
  $width: 50px,
  $height: 50px,
  $color: #999
  ) {
  position: relative;
  &:#{$before-or-after} {
    content: "";
    display: block;
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $width;
    height: $height;
    @if $top == 50% or $bottom == 50% {
      top: 0;
      bottom: 0;
      margin: auto;
    }
    @if $right == 50% or $left == 50% {
      right: 0;
      left: 0;
      margin: auto;
    }
    @if $direction == topRight {
      background: linear-gradient(to top right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
    }
    @if $direction == topLeft {
      background: linear-gradient(to top left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
    }
    @if $direction == bottomRight {
      background: linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
    }
    @if $direction == bottomLeft {
      background: linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
    }
  }
}

// 丸（●）
// @include icoCircle(before or after, top, right, bottom, left, 大きさ, 色);
@mixin icoCircle(
  $before-or-after,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $circleSize: 18px,
  $circleColor: #999
  ) {
  position: relative;
  &:#{$before-or-after} {
    content: "";
    display: block;
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $circleSize;
    height: $circleSize;
    @if $top == 50% or $bottom == 50% {
      top: 0;
      bottom: 0;
      margin: auto;
    }
    @if $right == 50% or $left == 50% {
      right: 0;
      left: 0;
      margin: auto;
    }
    background-color: $circleColor;
    border-radius: 50%;
  }
}

// プラス（＋）、マイナス（－）、バツ（✕）
// @include icoSymbol(plus or minus or cross, top, right, bottom, left, 大きさ, 線の幅, 色);
@mixin icoSymbol(
  $plus-or-minus-or-cross,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $size: 40px,
  $borderWidth: 5px,
  $color: #999
  ) {
  position: relative;
  &:before,
  &:after {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    @if $plus-or-minus-or-cross == plus {
      margin-top: ($size / 2) - ($borderWidth / 2);
      margin-bottom: ($size / 2) - ($borderWidth / 2);
    }
    @if $plus-or-minus-or-cross == cross {
      margin-top: ($size / 2) - ($borderWidth / 2) - 2;
      margin-bottom: ($size / 2) - ($borderWidth / 2) - 2;
    }
    @if $top == 50% {
      margin-top: -($borderWidth / 2);
    }
    @if $bottom == 50% {
      margin-bottom: -($borderWidth / 2);
    }
    @if $right == 50% {
      margin-right: -($size / 2);
    }
    @if $left == 50% {
      margin-left: -($size / 2);
    }
  }
  @if $plus-or-minus-or-cross == minus {
    &:after {
      content: "";
      display: block;
      width: $size;
      height: $borderWidth;
      background-color: $color;
    }
  } @else {
    &:before {
      content: "";
      display: block;
      width: $size;
      height: $borderWidth;
      background-color: $color;
      @if $plus-or-minus-or-cross == plus {
        transform: rotate(90deg);
      }
      @if $plus-or-minus-or-cross == cross {
        transform: rotate(45deg);
      }
    }
    &:after {
      content: "";
      display: block;
      width: $size;
      height: $borderWidth;
      background-color: $color;
      @if $plus-or-minus-or-cross == cross {
        transform: rotate(-45deg);
      }
    }
  }
}

// メニュー（三）
// @include icoMenu(外枠の幅, 外枠の高さ, top, right, bottom, left, 線の幅, 線の高さ, 角丸, 線の色, 線の間隔, ラベル文言);
@mixin icoMenu(
  $outerBoxWidth: 44px,
  $outerBoxHeight: 44px,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $borderWidth: 28px,
  $borderHeight: 2px,
  $borderRadius: 2px,
  $borderColor: #fff,
  $borderSpace: 8px,
  $label: null
  ) {
  position: relative;
  width: $outerBoxWidth;
  height: $outerBoxHeight;
  // cursor: pointer;
  color: $borderColor;
  .icoMenu {
    position: absolute;
    @if $top == 50% {
      top: 50%;
      margin-top: $borderHeight /2 *(-1);
    }
    @if $bottom == 50% {
      bottom: 50%;
      margin-bottom: $borderHeight /2;
    }
    @if $right == 50% {
      right: 50%;
      margin-right: $borderWidth /2;
    }
    @if $left == 50% {
      left: 50%;
      margin-left: $borderWidth /2 *(-1);
    }
    @if $top != 50% or $right != 50% or $bottom != 50% or $right != 50% {
      top: $top;
      right: $right;
      bottom: $bottom;
      left: $left;
    }
  }
  .icoMenuInner,
  .icoMenuInner:before,
  .icoMenuInner:after {
    display: block;
    content: '';
    position: absolute;
    // width: $borderWidth;
    height: $borderHeight;
    border-radius: $borderRadius;
    background: $borderColor;
    transition: all 0.3s ease-in-out;
  }
  .icoMenuInner{
    width: $borderWidth;
  }
  .icoMenuInner:before {
    top: $borderSpace * -1;
    width: $borderWidth - 8px;
  }
  .icoMenuInner:after {
    bottom: $borderSpace * -1;
    width: $borderWidth - 8px;
  }
  @if $label {
    &:before {
      content: $label;
    }
  }
  // クリックした時に✕に変更
  &.is-active .icoMenuInner {
    background-color: transparent;
  }
  &.is-active .icoMenuInner:before,
  &.is-active .icoMenuInner:after {
    top: 0;
    bottom: 0;
    // color: #fff;
    width: $borderWidth;
    background: $borderColor;
  }
  &.is-active .icoMenuInner:before {
    transform: rotate(45deg);
  }
  &.is-active .icoMenuInner:after {
    transform: rotate(-45deg);
  }
}

// 画像でアイコン挿入
// @include icoImage(before or after, top, right, bottom, left, 幅, 高さ, "パス");
@mixin icoImage(
  $before-or-after,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $imgSizeW: null,
  $imgSizeH: null,
  $imgPath: null
  ) {
  position: relative;
  &:#{$before-or-after} {
    content: "";
    display: block;
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $imgSizeW;
    height: $imgSizeH;
    @if $top == 50% {
      margin-top: -($imgSizeH / 2);
    }
    @if $bottom == 50% {
      margin-bottom: -($imgSizeH / 2);
    }
    @if $right == 50% {
      margin-right: -($imgSizeW / 2);
    }
    @if $left == 50% {
      margin-left: -($imgSizeW / 2);
    }
    background: url($imgPath) no-repeat 0 0;
    background-size: $imgSizeW $imgSizeH;
  }
}

// アイコンフォントインライン配置
// @include iconFontInline(before or after, フォント名, サイズ, 色, 左右マージン);
@mixin iconFontInline(
  $before-or-after,
  $fontName,
  $fontSize: null,
  $color: null,
  $horizontalMargin: 4px
  ) {
  &:#{$before-or-after} {
    // content: map-get($fontMap, $fontName);
    content: unquote('\"\\') + unquote(map-get($fontMap, $fontName)) + unquote('\"');
    @if $before-or-after == before {
      margin-right: $horizontalMargin;
    }
    @if $before-or-after == after {
      margin-left: $horizontalMargin;
    }
    color: $color;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: $fontSize;
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
    text-decoration: none;
    vertical-align: middle;
  }
}

// アイコンフォント絶対配置
// @include iconFontAbsolute(before or after, フォント名, top, right, bottom, left, サイズ, 色);
@mixin iconFontAbsolute(
  $before-or-after,
  $fontName,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $fontSize: null,
  $color: null
  ) {
  position: relative;
  &:#{$before-or-after} {
    // content: map-get($fontMap, $fontName);
    content: unquote('\"\\') + unquote(map-get($fontMap, $fontName)) + unquote('\"');
    position: absolute;
    @if $top == 50% or $bottom == 50% {
      @if $right == 50% or $left == 50% {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @else {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @else {
      top: $top;
      bottom: $bottom;
    }
    @if $right == 50% or $left == 50% {
      @if $top == 50% or $bottom == 50% {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @else {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    @else {
      right: $right;
      left: $left;
    }
    color: $color;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: $fontSize;
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
    text-decoration: none;
  }
}
// アイコンフォント変更
// @include iconFontChange(フォント名);
@mixin iconFontChange(
  $fontName
  ) {
    content: unquote('\"\\') + unquote(map-get($fontMap, $fontName)) + unquote('\"');
}