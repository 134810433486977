/* animation */
@-webkit-keyframes slideD1 {
	from {
		top: 30%;
		opacity: 0; }

	to {
		top: 22%;
		opacity: 1; } }

@-webkit-keyframes slideD2 {
	from {
		bottom: -5%;
		opacity: 0; }

	to {
		bottom: 0;
		opacity: 1.0; } }

@-webkit-keyframes slideD1_sp {
	from {
		top: 10%;
		opacity: 0; }

	to {
		top: 5%;
		opacity: 1; } }

@-webkit-keyframes slideD2 {
	from {
		bottom: -5%;
		opacity: 0; }

	to {
		bottom: 0;
		opacity: 1.0; } }

.main-banner {
	.deco-top {
		animation: slideD1 2s forwards; }

	.deco-bottom {
		animation: slideD2 2s forwards; } }

/* corona */
@-webkit-keyframes flower-deco1 {
	from {
		margin-left: -100px;
		opacity: 0; }

	to {
		margin-left: 0;
		opacity: 1; } }

@-webkit-keyframes flower-deco2 {
	from {
		margin-left: 100px;
		opacity: 0; }

	to {
		margin-left: 0;
		opacity: 1; } }

.flower-deco1.animated {
	animation: flower-deco1 2s forwards; }

.flower-deco2.animated {
	animation: flower-deco2 2s forwards; }

/* news */
@-webkit-keyframes deco1 {
	from {
		top: 15%;
		opacity: 0; }

	to {
		top: 10%;
		opacity: 1; } }

.deco1.animated, .deco2.animated {
	animation: deco1 2s forwards; }

@-webkit-keyframes deco3 {
	from {
		top: 75%;
		opacity: 0; }

	to {
		top: 70%;
		opacity: 1; } }

.deco3.animated, .deco4.animated {
	animation: deco3 2s forwards; }

/* calendar */
@-webkit-keyframes cal-tree1 {
	from {
		top: 5%;
		opacity: 0; }

	to {
		top: 0;
		opacity: 1; } }

.cal-tree1.animated, .cal-tree2.animated {
	animation: cal-tree1 2s forwards; }

@-webkit-keyframes cal-bug1 {
	from {
		top: 30%;
		opacity: 0; }

	to {
		top: 25%;
		opacity: 1; } }

.cal-bug1.animated {
	animation: cal-bug1 2s forwards; }

@-webkit-keyframes cal-bug2 {
	from {
		top: 60%;
		opacity: 0; }

	to {
		top: 55%;
		opacity: 1; } }

.cal-bug2.animated {
	animation: cal-bug2 2s forwards; }

@-webkit-keyframes cal-bird {
	from {
		bottom: -5%;
		opacity: 0; }

	to {
		bottom: 0;
		opacity: 1; } }

.cal-bird.animated {
	animation: cal-bird 2s forwards; }

@-webkit-keyframes cal-grass1 {
	from {
		right: -100px;
		opacity: 0; }

	to {
		right: 0;
		opacity: 1; } }

.cal-grass1.animated {
	animation: cal-grass1 2s forwards; }

@-webkit-keyframes cal-grass2 {
	from {
		left: -100px;
		opacity: 0; }

	to {
		left: 0;
		opacity: 1; } }

.cal-grass2.animated {
	animation: cal-grass2 2s forwards;
	animation: cal-grass2 2s forwards; }

@-webkit-keyframes cal-grass2 {
	from {
		left: -100px;
		opacity: 0; }

	to {
		left: 0;
		opacity: 1; } }

/* greeting */
@-webkit-keyframes greet-deco-1 {
	from {
		left: 0;
		opacity: 0; }

	to {
		left: 2%;
		opacity: 1; } }

.greet-deco-1.animated {
	animation: greet-deco-1 2s forwards; }

@-webkit-keyframes greet-deco-2 {
	from {
		right: 0;
		opacity: 0; }

	to {
		right: 2%;
		opacity: 1; } }

.greet-deco-2.animated {
	animation: greet-deco-2 2s forwards; }

@-webkit-keyframes greet-child-1 {
	from {
		bottom: 0%;
		opacity: 0; }

	to {
		bottom: 5%;
		opacity: 1; } }

.greet-child-1.animated {
	animation: greet-child-1 2s forwards; }

@-webkit-keyframes greet-child-2 {
	from {
		top: 35%;
		opacity: 0; }

	to {
		top: 30%;
		opacity: 1; } }

.greet-child-2.animated {
	animation: greet-child-2 2s forwards; }

@-webkit-keyframes greet-tree-1 {
	from {
		top: 55%;
		opacity: 0; }

	to {
		top: 50%;
		opacity: 1; } }

.greet-tree-1.animated {
	animation: greet-tree-1 2s forwards; }

@-webkit-keyframes greet-tree-2 {
	from {
		top: 20%;
		opacity: 0; }

	to {
		top: 15%;
		opacity: 1; } }

.greet-tree-2.animated {
	animation: greet-tree-2 2s forwards; }

/* features */
@-webkit-keyframes feature-grass1 {
	from {
		margin-left: -100px;
		opacity: 0; }

	to {
		margin-left: 0;
		opacity: 1; } }

.feature-grass1.animated {
	animation: feature-grass1 2s forwards; }

@-webkit-keyframes feature-grass2 {
	from {
		margin-left: 100px;
		opacity: 0; }

	to {
		margin-left: 0;
		opacity: 1; } }

.feature-grass2.animated {
	animation: feature-grass2 2s forwards; }

@-webkit-keyframes feature-tree1 {
	from {
		top: 20%;
		opacity: 0; }

	to {
		top: 15%;
		opacity: 1; } }

.feature-tree1.animated {
	animation: feature-tree1 2s forwards; }

@-webkit-keyframes feature-tree2 {
	from {
		top: 40%;
		opacity: 0; }

	to {
		top: 35%;
		opacity: 1; } }

.feature-tree2.animated {
	animation: feature-tree2 2s forwards; }

@-webkit-keyframes feature-tree3 {
	from {
		top: 65%;
		opacity: 0; }

	to {
		top: 60%;
		opacity: 1; } }

.feature-tree3.animated {
	animation: feature-tree3 2s forwards; }

@-webkit-keyframes feature-tree4 {
	from {
		top: 80%;
		opacity: 0; }

	to {
		top: 75%;
		opacity: 1; } }

.feature-tree4.animated {
	animation: feature-tree4 2s forwards; }

@-webkit-keyframes feature-couple1 {
	from {
		top: 22%;
		opacity: 0; }

	to {
		top: 17%;
		opacity: 1; } }

.feature-couple1.animated {
	animation: feature-couple1 2s forwards; }

@-webkit-keyframes feature-couple2 {
	from {
		top: 42%;
		opacity: 0; }

	to {
		top: 37%;
		opacity: 1; } }

.feature-couple2.animated {
	animation: feature-couple2 2s forwards; }

@-webkit-keyframes feature-couple3 {
	from {
		top: 65%;
		opacity: 0; }

	to {
		top: 60%;
		opacity: 1; } }

.feature-couple3.animated {
	animation: feature-couple3 2s forwards; }

@-webkit-keyframes feature-deco2 {
	from {
		left: 0;
		opacity: 0; }

	to {
		left: 3%;
		opacity: 1; } }

.feature-deco2.animated {
	animation: feature-deco2 2s forwards; }

/* service */
@-webkit-keyframes service-deco1 {
	from {
		left: -100px;
		opacity: 0; }

	to {
		left: 0;
		opacity: 1; } }

.service-deco1.animated {
	animation: service-deco1 2s forwards; }

@-webkit-keyframes service-deco2 {
	from {
		right: -100px;
		opacity: 0; }

	to {
		right: 0;
		opacity: 1; } }

.service-deco2.animated {
	animation: service-deco2 2s forwards; }

@-webkit-keyframes service-tree1 {
	from {
		top: 5%;
		opacity: 0; }

	to {
		top: 0;
		opacity: 1; } }

.service-tree1.animated {
	animation: service-tree1 2s forwards; }

/* clinic table */
@-webkit-keyframes clinic-tree {
	from {
		top: 25%;
		opacity: 0; }

	to {
		top: 20%;
		opacity: 1; } }

.clinic-tree1.animated, .clinic-tree2.animated {
	animation: clinic-tree 2s forwards; }

@-webkit-keyframes clinic-grass1 {
	from {
		left: -100px;
		opacity: 0; }

	to {
		left: 0;
		opacity: 1; } }

.clinic-grass1.animated {
	animation: clinic-grass1 2s forwards; }

@-webkit-keyframes clinic-grass2 {
	from {
		right: -100px;
		opacity: 0; }

	to {
		right: 0;
		opacity: 1; } }

.clinic-grass2.animated {
	animation: clinic-grass2 2s forwards; }

/* sns */
@-webkit-keyframes deco_sns_title {
	from {
		margin-top: 100px;
		opacity: 0; }

	to {
		margin-top: 0;
		opacity: 1; } }

#deco_sns_title.animated {
	animation: deco_sns_title 2s forwards; }

@-webkit-keyframes sns-couple1 {
	from {
		top: 35%;
		opacity: 0; }

	to {
		top: 30%;
		opacity: 1; } }

.sns-couple1.animated, .sns-couple2.animated {
	animation: sns-couple1 2s forwards; }

@-webkit-keyframes sns-couple3 {
	from {
		top: 87%;
		opacity: 0; }

	to {
		top: 82%;
		opacity: 1; } }

.sns-couple3.animated {
	animation: sns-couple3 2s forwards; }

@media screen and (max-width: 767px) {
	.deco-top {
		animation: slideD1_sp 2s forwards !important; } }

@media screen and (min-width: 768px) and (max-width: 950px) {
	.deco-top {
		animation: slideD1_sp 2s forwards !important; } }
