.l-main-clinic {
	position: relative;
	background: url(../images/park/clinic/clinic_bg_pc.png) top center;
	padding: 100px 0px 100px 0px;
	width: 100%;
	max-width: 100%;
	min-height: 50vh;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -18vh;
	margin-bottom: 50px; }

#clinic-top-menu {
	width: 500px;
	max-width: 100%;
	margin: 0px auto;
	padding: 20px; }

.l-main-clinic .menu-item {
	background-color: white;
	border: 2px solid #54A868;
	border-radius: 10px;
	width: 45%;
	height: 65px;
	box-shadow: 3px 3px #54A868;
	float: left;
	margin: 20px 2% 20px 3%;

	a {
		position: relative;
		padding: 0.8em 1em 1em 1em;
		text-decoration: none !important;
		font-size: 18px;
		display: block;
		color: #000000; }

	&:hover {
		transform: scale(1.1);
		transform-origin: center;
		transition: all .3s; }

	a {
		p {
			padding-left: 20%;
			padding-top: 4px;
			font-size: 18px; }

		&:after {
			position: absolute;
			right: 15%;
			top: 22%;
			content: url('../images/park/staff/arrow_down.png'); } } }

/*******************   about-clinic  ********************** */

.l-about-clinic {
	position: relative;
	padding: 100px 0px 50px;

	.c-title {
		color: #54A868;
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		letter-spacing: 5px; }

	.c-title-jp {
		color: #543C30;
		font-size: 16px; }

	#clinic-slider {
		position: relative;
		background: url(../images/park/clinic/about_bg_pc.png) top center;
		padding: 100px 0px 100px 0px;
		width: 100%;
		max-width: 100%;
		// min-height: 100vh
		background-size: cover;
		margin-top: 100px;

		.c-title {
			font-size: 20px;
			font-weight: bold;
			color: white;
			font-family: 'Kosugi Maru', sans-serif; }

		.c-help {
			font-size: 16px;
			color: white;
			margin-top: 20px;
			line-height: 2; } } }

.slider-item {

	img {
		border-radius: 20px; } }

.slider-labels {
	width: 100%;
	margin-top: 50px; }

.clinic-deco1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 35%; }

.clinic-deco2 {
	position: absolute;
	top: 0;
	right: 0;
	width: 35%; }

/*******************   about-facility  ********************** */

.l-about-facility {
	position: relative;
	padding: 100px 0px 20vh 0px;
	margin-bottom: 50px;

	.c-title {
		color: #54A868;
		font-size: 48px;
		font-family: 'Fredoka One', cursive; }

	.c-title-jp {
		color: #543C30;
		font-size: 16px; }

	#facility {
		width: 950px;
		max-width: 100%;
		padding: 10px;
		margin: 0px auto;

		.image-item {
			border: 2px solid #54A868;
			border-radius: 10px;
			padding: 20px;
			margin-bottom: 10px;

			.c-item-title {
				font-weight: bold;
				font-size: 22px; }

			.c-item-help {
				font-size: 16px; } } } }

/******************** slider ************************ */

.l-about-clinic #clinic-slider .slider {
	width: 100%;
	max-width: 500px;
	margin: 50px auto;
	@media screen and (max-width: 480px) {
		max-width: 300px; } }
.slick-list {
	overflow: visible; }
.l-about-clinic .slick-slide {
	margin: 0px 20px;
	width: 100%;
	max-width: 500px;
	@media screen and (max-width: 480px) {
		max-width: 300px; }
	// img
 }	//idth: 100%

.slick-prev {
	left: 50% !important;
	margin-left: -265px; }

.slick-next {
	right: 50% !important;
	margin-right: -265px; }

.slick-prev, .slick-next {
	background: #FF8266 !important;
	border-radius: 10px !important;
	width: 38px !important;
	height: 38px !important;
	z-index: 1;
	top: 30%; }

.slick-prev:before, .slick-next:before {
	font-family: initial !important;
	color: white !important;
	font-weight: bold; }

.slick-slide {
	transition: all ease-in-out .3s;
	opacity: .6; }

.slick-active {
	opacity: 0.6; }

.slick-current {
	opacity: 1; }

@media screen and (max-width: 767px) {
	.l-main-clinic {
		background: url(../images/park/clinic/clinic_bg_sp.png) no-repeat;
		background-size: 100% !important;
		margin-top: -24vh;
		padding-bottom: 0px !important;

		.menu-item a p {
			padding-left: 15%; } }

	.l-about-clinic .c-title, .l-about-facility .c-title {
		font-size: 32px; }

	.l-main-clinic .top-menu {
		padding-left: 5%; }

	.l-about-clinic {
		padding-bottom: 0px !important;

		#clinic-slider {
			background: url(../images/park/clinic/about_bg_sp.png) no-repeat;
			background-size: 100% !important;
			// padding: 10px 0px
			margin-top: 20px;
			padding: 30px 0px 100px 0px;

			.slider {
				margin: 0px auto; }

			.c-title {
				margin-top: -70px;
				line-height: 1; } } }

	.slick-prev {
		margin-left: -48vw; }

	.slick-next {
		margin-right: -48vw; }

	// .slider-item
	//idth: auto !important

	.slider-labels {
		margin-top: 100px; }

	#clinic-top-menu {
		padding-top: 0px !important; } }

@media screen and (min-width: 768px) and (max-width: 950px) {
	.l-main-clinic {
		.menu-item {
			width: 45%;
			margin-right: 5%; }

		.top-menu {
			padding-left: 5%; } } }
