/*
 * Add yoshida stylesheet
 * */

/* -------------------------
 * module
 * ------------------------- */
.is-single {
	justify-content: center; }
.l-banner {
	background-size: 1920px 400px;
	background-position: bottom center;
	@media screen and (min-width: 768px) and (max-width: 950px) {
		margin-top: 80px; }
	@media screen and (max-width: 767px) {
		min-height: 300px;
		// background-position: top center
		// position: relative
		// z-index: 0
		// &::before
		//   content: ''
		//   position: absolute
		//   top: 0
		//   left: 0
		//   width: 100%
		//   height: 50%
		//   background-color: #ccc
 }		//   z-index: -1
	@media screen and (max-width: 576px) {
		background-size: 100% auto;
		background-position: bottom 85px center; } }

#breadcrumb {
	transform: translateY(-40px);
	z-index: 1;
	position: relative; }
.c-flex {
	display: flex;
	flex-wrap: wrap;
	&.is-space-between {
		justify-content: space-between; }
	&.is-center {
		justify-content: center; }
	&.is-left-justified {
		&::after {
			content: "";
			display: block;
			width: 31%; } }
	@media screen and (max-width: 767px) {
		display: block;
		>*:not(:first-of-type) {
			margin-top: 30px; } } }
.c-title-jp {
	position: relative;
	padding-left: 60px;
	padding-right: 60px;
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	&::before,&::after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin: 0; }
	&::before {
		left: 0; }
	&::after {
		right: 0; } }


/* -------------------------
 * header
 * ------------------------- */
.main-header {
	.logo {
		margin-top: 60px;
		width: 100%;
		max-width: 350px;
		position: relative;
		line-height: 1.2;
		@media (min-width: 951px) and (max-width: 1450px) {
			margin-top: 40px; }
		@media (max-width: 950px) {
			margin-top: 20px;
			max-width: calc(100% - 70px); }
		@media (max-width: 480px) {
			padding-left: 10px !important; }
		.help {
			position: absolute;
			left: 40px;
			top: -50px;
			white-space: nowrap;
			@media (min-width: 951px) and (max-width: 1450px) {
				margin-top: 0; } }
		a {
			font-size: 26px;
			@media (max-width: 480px) {
				font-size: 6vw; } } }
	#reserve .item .phone a {
		color: #fff; }
	.header-table tbody tr:last-child {
		th {
			border-bottom-left-radius: 8px; }
		td:last-child {
			border-bottom-right-radius: 8px; } } }
nav.main-nav {
	// margin-top: 20px
	width: 100%;
	max-width: calc(100% - 380px - 660px);
	.header_menu {
		text-align: left; }
	@media (max-width: 1450px) {
		// max-width: calc(100% - 350px - 530px)
		.main-menu {
			margin-left: 0; } }
	@media (max-width: 1200px) {
		max-width: calc(100% - 350px); }
	@media (max-width: 950px) {
		margin-top: 0;
		max-width: calc(100%); } }
.main-header {
	@media (max-width: 950px) {
		height: 100px; } }
a.menu-link {
	@media screen and (max-width: 950px) {
		padding-top: 20px; } }
/* -------------------------
 * TOP
 * ------------------------- */
 /* MV */
.main-banner .caption {
	h2 {
		margin-top: 0px;
		font-size: 24px;
		text-transform: uppercase;
		display: inline;
		font-weight: 600;
		color: #543C30;
		line-height: 2.2;
		letter-spacing: 1px;
		background-color: white; }
	@media screen and (max-width: 950px) {
		width: 100%;
		transform: translate(0, 0);
		left: 0;
		padding: 0 15px; } }
/* 開院のお知らせ */
.c-date {
	margin-top: 30px;
	font-size: 2.0rem;
	line-height: 1.6;
	color: #FF8266; }
/* -------------------------
 * news
 * ------------------------- */
.l-news .l-news-rect .item a {
	color: #212529;
	font-weight: normal;
	.c-news_ico,.c-information_ico {
		display: inline-block;
		border-radius: 10px;
		line-height: 1.0;
		padding: 5px 10px;
		font-size: 14px;
		color: #fff; }
	.c-news_ico {
		background-color: #FF8266; }
	.c-information_ico {
		background-color: #F4CF56; } }
/* -------------------------
 * service
 * ------------------------- */
.l-service .service-item {
	width: 100%;
	flex-direction: column;
	a {
		color: $keyColor; }
	.service-icon {
		padding-top: 0;
		margin-bottom: 0;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 80px; } }
	.service-title {
		padding-top: 0; } }
.l-service  a {
	display: flex;
	height: 100%;
	width: 100%;
	color: $keyColor; }
.service-item-content > div {
	margin-bottom: 100px; }

/* -------------------------
 * clinic
 * ------------------------- */
.l-clinic .owl-carousel {
	.owl-stage {
		display: flex; }
	@media screen and (max-width: 767px) {
		margin-left: -30px;
		margin-right: -30px;
		padding: 0 15px;
		width: calc(100% + 60px); } }

/* -------------------------
 * freearea
 * ------------------------- */
.l-freearea {
	position: relative;
	background: url(../images/park/top/top_greeting_bg_pc.png) top center;
	padding: 20px 0px 0 0px;
	width: 100%;
	max-width: 100%;
	background-size: cover;
	margin: 15vh auto;
	& + .l-freearea {
		margin-top: 0; } }
#freearea {
	// max-width: 100%
	margin: 0px auto;
	max-width: 1050px;
	padding: 10px;
	@media screen and (max-width: 576px) {
		.row {
			margin-right: 0;
			margin-left: 0; } } }
/* -------------------------
 * calendar
 * ------------------------- */
.l-calendar .c-item {
	height: auto; }
.l-calendar .c-item .phone {
	font-size: 2.0rem;
	display: block; }
.c-flex_double {
	>* {
		display: flex; } }
/* -------------------------
 * footer
 * ------------------------- */
.footer-sns {
	display: flex;
	align-items: center;
	justify-content: center;
	li {
		margin: 0 10px;
		a {
			color: #fff; } } }
.l-footer-reserve {
	.phone {
		font-size: 2.0rem;
		display: block;
		a {
			color: #fff;
			font-family: 'Fredoka One', cursive;
			letter-spacing: 1px; } }
	.contact .c-item {
		height: auto;
		a {
			color: #fff; } }
	.dotted {
		a {
			color: #fff;
			text-decoration: underline;
			&:hover {
				text-decoration: none; } }
		& + .dotted {
			margin-top: 30px; } }
	.time-table tbody tr:last-child {
		th {
			border-bottom-left-radius: 8px; }
		td:last-child {
			border-bottom-right-radius: 8px; } }
	#map {
		height: auto;
		iframe {
			width: 100%;
			height: 400px; } }
	.c-intro-text {
		color: #fff; }
	.contact {
		&:not(.is-info) {
 }			// width: auto
		.c-rects {
			width: auto;
			display: flex;
			justify-content: center;
			.rect {
				justify-content: center;
				width: 100%;
				height: auto; } } } }
.l-footer-top {
	margin-top: -15vh; }
@media screen and (max-width: 950px) {
	.l-footer-reserve .contact .c-item .c-item-title h4,
	.l-calendar .c-item .c-item-title h4 {
		font-size: 2.2vw; } }
@media screen and (max-width: 767px) {
	.c-item .btn-newer, .c-item .btn-retest {
		display: block; }
	// .c-button-title
 }	//adding-top: 5px
@media screen and (max-width: 576px) {
	.l-footer-reserve {
		.phone {
			font-size: 1.5rem; }
		.contact .c-item {
			padding: 10px 15px; } }
	.l-footer-reserve .contact .c-item .c-item-title h4,
	.l-calendar .c-item .c-item-title h4 {
		font-size: 4vw; } }



/* -------------------------
 * access page
 * ------------------------- */
.l-main-access {
	#g-map {
		height: auto;
		iframe {
			width: 100%;
			height: 400px; } }
	#l-access-detail {
		.phone a {
			color: #212529; }
		.text-item {
			padding: 20px 10px;
			flex-basis: 31%;
			@media screen and (max-width: 767px) {
				>*:nth-of-type(n+1) {
					margin-top: 30px; } } }
		.image-item {
			flex-basis: 31%;
			&:nth-of-type(n+4) {
				margin-top: 30px; } } }
	.time-table tbody tr:last-child {
		th {
			border-bottom-left-radius: 8px; }
		td:last-child {
			border-bottom-right-radius: 8px; } } }

/* -------------------------
 * service page
 * ------------------------- */
.l-service-top {
	background-size: 1920px auto;
	background-repeat: no-repeat;
	min-height: 75vh;
	padding: 140px 0px 50px 0px;
	#service-menu ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&::after, &::before {
			content: "";
			display: block;
			width: 23%;
			height: 0; }
		&::before {
			order: 1; } }
	.menu-item {
		height: auto;
		float: none;
		margin-right: 0;
		width: 23%;
		a {
			display: flex;
			align-items: center;
			padding: 0.5em 1em 0.7em 1em;
			text-align: center;
			height: 100%;
			.icon {
				flex-basis: 30%;
				margin-bottom: 0;
				text-align: center;
				padding-right: 10px;
				img {
					max-height: 50px;
					margin-right: 0; } }
			.text {
				flex-basis: 70%;
				margin-bottom: 0;
				text-align: left; } } }
	@media screen and (min-width: 768px) and (max-width: 950px) {
		min-height: 58vh; }
	@media screen and (max-width: 767px) {
		.menu-item {
			width: 47%;
			a {
				padding: 0.5em 0.5em 0.7em 0.5em;
				.icon {
					flex-basis: 25%;
					img {
						margin-left: 0;
						transform: scale(1);
						margin-top: 0px;
						max-height: 40px; } }
				.text {
					flex-basis: 75%;
					line-height: 1.4;
					font-size: 1.0rem; } } } }
	@media screen and (max-width: 576px) {
		background-size: 100% auto; } }
.l-service-content {
	.service-deco1 {
		// width: 10%
 }		// left: 50px
	.service-item-text {
		padding-left: 15px; }
	.service-item-image {
		border-radius: 20px;
		overflow: hidden; }
	.service-item-content {
		.c-detail-help {
			margin-left: 0; }
		.c-detail-list {
			padding-left: 20px;
			li {
				margin-top: 10px; } } }
	@media screen and (max-width: 767px) {
		.service-item-content .c-detail-list {
			margin: 20px 0px 0px 0; } }
	@media screen and (max-width: 576px) {
		.service-deco1 {
			width: 35%;
			top: -70px; }
		.service-deco4 {
			width: 35%;
			top: -5%; } } }

/* -------------------------
 * staff page
 * ------------------------- */
.l-staff-top {
	min-height: 48vh;
	.philosophy {
		text-align: center;
		max-width: 1150px;
		margin: 100px auto 0px; }
	.staff-menu ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center; }
	.menu-item {
		margin-top: 0; }
	@media screen and (max-width: 576px) {
		background-size: cover;
		background-position: top center;
		padding-bottom: 50px;
		.philosophy {
			margin: 50px 15px 0px; } } }
.l-staff-staff {
	.l-staff-text {
		margin-top: 100px;
		text-align: center;
		font-size: 16px;
		line-height: 2;
		@media screen and (max-width: 576px) {
			margin-top: 50px;
			text-align: left; } }
	.l-staff-pic {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		img {
			max-width: 100%; } }
	@media screen and (max-width: 576px) {
		.l-staff-text {
			padding: 0 15px; }
		.l-staff-pic {
			display: block; } } }
.l-staff-recruit {
	.l-recruit-text {
		margin-top: 60px;
		text-align: center;
		font-size: 16px;
		line-height: 2;
		@media screen and (max-width: 767px) {
			margin: 30px 15px 0;
			text-align: left; } }
	.l-recruit-pic {
		display: flex;
		flex-grow: 1;
		justify-content: center; } }

/* -------------------------
 * clinic page
 * ------------------------- */
.l-main-clinic {
	min-height: 50vh;
	#clinic-top-menu ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center; }
	@media screen and (max-width: 576px) {
		margin-top: -20vh;
		#clinic-top-menu {
			margin-top: 30px;
			padding: 0;
			padding-bottom: 0; } } }
/* -------------------------
 * facilities page
 * ------------------------- */
.l-about-facility {
	#facility .image-item {
		margin-bottom: 30px; }
	.facility-lead {
		margin-top: 30px;
		text-align: center;
		width: 100%;
		font-size: 1.2rem; }
	.facility-list {
		margin-top: 60px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		>* {
			flex-basis: 31%; }
		&::after {
			content: "";
			display: block;
			width: 31%;
			height: 0; } }
	@media screen and (max-width: 767px) {
		.facility-list {
			>* {
				font-size: 48%; } } }
	@media screen and (max-width: 576px) {
		background-size: 100% auto;
		.facility-lead {
			padding: 0 30px;
			font-size: 1.2rem;
			text-align: left; }
		.facility-list {
			display: block; } } }

/* ---------------------------------------------------------
 *  SP TEL/WEB button
 *---------------------------------------------------------- */
#fixedMenu {
	display: none;
	@media screen and (max-width: 767px) {
		display: flex;
		justify-content: center;
		position: fixed;
		bottom: 0;
		left: 0;
		width: calc(100% - 50px);
		z-index: 100;
		&.is-switch {
			#telBtn {
				order: 2; }
			#webBtn {
				order: 1; } } } }
#telBtn,#webBtn {
	@media screen and (max-width: 767px) {
		flex-basis: 50%;
		display: flex;
		border-top: 1px solid #fff;
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
		a {
			height: 48px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #54A868;
			color: #fff;
			position: relative;
			span {
				display: inline-block;
				font-size: 0.9rem;
				text-shadow: 0 0 3px $keyColor, 0 0 3px $keyColor; }
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%; } } } }


.c-layout_pattern {
	padding-bottom: 15vh; }
