@charset "utf-8";

/* ---------------------------------------------------------
 *	mwform
 *---------------------------------------------------------- */
#main .mw_wp_form {
  max-width: 800px;
  margin: 0 auto 0;
  padding: $sectionMargin*.5 0;
  font-size: 1.6rem;
  text-align: left;
  form>p {
    line-height: 1.8;
    font-size: 1.0rem;
    margin-bottom: 0; }
  @include media(xxl);
  @include media(xl);
  @include media(lg) {
    padding: $sectionMargin*.5 15px; }
  @include media(md);
  @include media(sm); }

#main .mw_wp_form {
  input[type="submit"] {
    cursor: pointer;
    margin-top: $sectionMargin*.3;
    text-align: center;
    display: inline-block;
    min-width: 220px;
    padding: 15px 20px;
    transition: all .3s;
    background-color: $baseButtonColor;
    color: #fff;
    line-height: 1.4;
    font-size: 1.2rem;
    border: none;
    &.is-hover,&:hover {
      background-color: $baseButtonColorHover; }
    @include media(xxl);
    @include media(xl);
    @include media(lg);
    @include media(md) {
      margin-top: $sectionMargin*.2; }
    @include media(sm) {
      font-size: 3.8vw; } }
  input[name*="postcode"],input[name*="tel"] {
    @include media(lg) {
      max-width: 26vw; } } }
#main .mw_wp_form {
  .mwform-checkbox-field , .mwform-radio-field {
    margin-right: 20px;
    display: inline-block; }
  @include media(md) {
    .mwform-checkbox-field , .mwform-radio-field {
      margin: 0 10px 0 0; } } }
#main .mw_wp_form {
  form>p>input[type="text"],form>p>input[type="email"],
  textarea {
    width: 100%; } }
