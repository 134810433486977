@charset "utf-8";

/* ---------------------------------------------------------
 *	ウィジウィグ
 *---------------------------------------------------------- */
.p-information .m-breadcrumbs .m-breadcrumbs_list {
	// max-width: 1560px
 }	// padding: 0 30px
.m-single {
	max-width: $baseWidth;
	margin: 0 auto;
	@include media(lg) {
		margin: 0;
		display: block; }
	@include media(md); }
/* ---------------------------------------------------------
 *	navi
 *---------------------------------------------------------- */
.m-single_navi {
	flex-basis: $baseSideColumn+ + 40px;
	display: flex;
	justify-content: space-between;
	margin-top: $sectionMargin;
	padding-top: $sectionMargin;
	border-top: 3px solid $keyColor;
	.m-single_naviBlk {
		flex-basis: 48%; }
	.m-single_naviTtl {
		font-size: 2.6rem;
		font-weight: bold;
		position: relative;
		letter-spacing: 2px;
		color: $keyColor; }
	.m-single_naviList {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		>li {
			font-size: 1.8rem;
			font-weight: bold;
			flex-basis: 48%;
			a {
				display: block;
				padding: 10px 10px;
				padding-right: 2.5rem;
				color: $keyColor;
				transition: all .3s;
				border-bottom: 1px solid lighten($keyColor, 32%);
				color: $keyColor;
				&::before {
					transition: all .3s; }
				&.is-hover,&:hover {
					background-color: $keyColor;
					color: #fff; }
				span {
					font-size: 1.4rem; } } } }
	@include media(lg) {
		display: block;
		.m-single_naviBlk + .m-single_naviBlk {
			margin-top: $sectionMargin; }
		.m-single_naviList {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			>li {
				flex-basis: 48%; } } }
	@include media(md) {
		margin-top: $sectionMargin*.5;
		padding-top: $sectionMargin*.5;
		.m-single_naviBlk + .m-single_naviBlk {
			margin-top: $sectionMargin*.5; }
		.m-single_naviTtl {
			font-size: 2.2rem;
			padding-bottom: 0px; }
		.m-single_naviList {
			margin-top: 10px;
			display: block;
			>li {
				font-size: 1.4rem;
				a {
					padding: 10px; } } } } }
/* ---------------------------------------------------------
 *	main
 *---------------------------------------------------------- */
.m-single_main {
 }	// flex-basis: calc(100% - #{$baseSideColumn} - #{$sectionMargin} - 40px)
/* ---------------------------------------------------------
 *	タイトル
 *---------------------------------------------------------- */
.m-single_heading {
	// max-width: $baseWidth
 }	// margin: 0 auto
.m-single_title {
	font-size: 3.4rem;
	font-weight: 700;
	max-width: $baseWidth;
	margin: 0 auto $sectionMargin*.5;
	line-height: 1.4;
	font-family: 'Kosugi Maru', sans-serif;
	@include media(lg);
	@include media(md) {
		font-size: 2.6rem;
		margin: 0 auto $sectionMargin*.3; } }
.m-single_option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px; }
.m-single_category {
	// border: 1px solid #b5b5b5
	// padding: 8px 30px
	// background-color: $contetntsEvenBGC
	span {
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 2rem;
		color: $keyColor;
		&::before {
			content: 'CATEGORY';
			display: inline-block;
			margin-right: 20px;
			font-size: 1.4rem;
			line-height: 1.2;
			letter-spacing: 4px;
			font-weight: normal;
			height: 100%;
			padding-right: 10px;
			border-right: 1px solid $keyColor;
 } }			// opacity: .5
	@include media(lg);
	@include media(md) {
		span {
			font-size: 1.6rem;
			&::before {
				font-size: 1.2rem; } } } }
.m-single_date {
	display: inline-block;
	// border-bottom: 1px solid $baseTextColor
	// padding: 5px 20px
	span {
		font-size: 1.6rem;
		font-weight: bold;
		// font-family: $fontFamilyOswald
		display: inline-block;
		padding: 5px 20px;
		letter-spacing: 2px;
		color: $keyColor;
		background-color: lighten($keyColor,30%); }
	@include media(lg);
	@include media(md) {
		span {
			font-size: 1.4rem;
			padding: 5px 10px; } } }
.m-single_catch {
	text-align: center;
	margin-bottom: $sectionMargin*.5;
	@include media(md) {
		margin: 0 auto $sectionMargin*.5; } }
/* ---------------------------------------------------------
 *	一覧
 *---------------------------------------------------------- */
.m-single_archiveTtl {
	font-size: 3.6rem;
	font-weight: bold;
	position: relative;
	max-width: $baseWidth;
	margin: 0 auto $sectionMargin;
	color: $keyColor;
	&::before {
		// position: absolute
		// left: 0
		// bottom: 0
		// font-family: $fontFamilyOswald
		font-size: 2rem;
		line-height: 1.2;
		letter-spacing: 2px;
		font-weight: normal;
		// opacity: .5
		margin-right: 30px;
		color: $keyColor; }
	&.is-category {
		&::before {
			content: 'CATEGORY :'; } }
	&.is-archive {
		&::before {
			content: 'ARCHIVE :'; } }
	@include media(md) {
		font-size: 2rem;
		margin-bottom: $sectionMargin*.5;
		&::before {
			margin-right: 20px;
			font-size: 1.0rem; } } }
.m-single_article {
	max-width: $baseWidth;
	margin: 0 auto;
	article {
		& + article {
			margin-top: $sectionMargin*.3; }
		// &:nth-of-type(odd)
		//ackground-color: rgba($keyColor, .1)
		a {
			// display: flex
			// padding: 30px 40px
			display: block;
			padding-bottom: $sectionMargin*.3;
			border-bottom: 1px solid $baseBorderColor;
			transition: all .3s;
			&.is-hover,&:hover {
				// opacity: .7
				.m-single_title {
					text-decoration: underline; } } } }
	.m-single_option {
		justify-content: flex-start;
		margin-bottom: 15px;
		align-items: flex-end;
		@include media(md) {
			justify-content: space-between; } }
	.m-single_category {
		flex-basis: auto;
		order: 2;
		padding: 6px 20px;
		span {
			font-size: 1.6rem;
			&::before {
				letter-spacing: 2px;
				font-size: 1.2rem; } }
		@include media(md) {
			padding: 6px 10px;
			span {
				&::before {
					margin-right: 5px;
					letter-spacing: 2px;
					font-size: 1.0rem; } } } }
	.m-single_date {
		order: 1;
		// margin-right: 30px
		@include media(md) {
 }			// margin-right: 10px
 }		// flex-basis: 140px
	.m-single_title {
		flex-basis: 100%;
		font-size: 2.6rem;
		margin-bottom: 0;
		@include media(lg);
		@include media(md) {
			font-size: 2.0rem; } } }
/* ---------------------------------------------------------
 *	記事
 *---------------------------------------------------------- */
.m-single_body {
	// max-width: 810px
	max-width: $baseWidth;
	margin: $sectionMargin*.2 auto 0;
	font-size: 1.6rem;
	p,div,table,img,ul,ol {
		margin-bottom: 30px;
 }		// font-size: 1.6rem
	table {
		border-top: 1px solid $baseBorderColor;
		border-left: 1px solid $baseBorderColor;
		th {
			background-color: #adadad; }
		td,th {
			font-size: 1.2rem;
			padding: 5px 10px;
			border-bottom: 1px solid $baseBorderColor;
			border-right: 1px solid $baseBorderColor; } }
	h1,h2,h3,h4,h5,h6 {
		font-weight: 700;
		margin-bottom: 3rem;
		line-height: 1.4; }
	h1 {
		font-size: 2.6rem;
		border-bottom: 3px solid $keyColor;
		position: relative;
		padding-bottom: 10px;
		// color: $keyColor
		// &::before
		//ontent: ''
		//osition: absolute
		//eft: 0
		//ottom: -3px
		//idth: 200px
		//eight: 3px
 }		//ackground-color:  $keyColor
	h2 {
		font-size: 2.4rem;
		border-bottom: 3px solid $baseBorderColor;
		position: relative;
		padding-bottom: 10px;
		color: $keyColor;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 200px;
			height: 3px;
			background-color:  $keyColor; } }
	h3 {
		font-size: 2.2rem;
		padding-bottom: 10px;
		border-bottom: 3px solid $baseBorderColor; }
	h4 {
		font-size: 2.0rem;
		padding-bottom: 10px;
		border-bottom: 02px solid $baseBorderColor; }
	h5 {
		font-size: 1.8rem;
		padding-bottom: 10px;
		border-bottom: 1px solid $baseBorderColor; }
	h6 {
		font-size: 1.6rem;
		padding-bottom: 5px;
		border-bottom: 1px solid $baseBorderColor; }
	a {
		text-decoration: underline;
		color: #0070c9;
		&:hover {
			text-decoration: none; } }
	.aligncenter {
		margin-left: auto;
		margin-right: auto;
		display: block; }
	.alignleft {
		display: block; }
	.alignright {
		margin-left: auto;
		display: block; }
	ul {
		li {
			list-style-type: disc;
			list-style-position: inside; } }
	ol {
		li {
			list-style-type: decimal;
			list-style-position: inside; } }
	.googlemap {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		height: 0;
		overflow: hidden;
		padding-bottom: 45.75%;
		position: relative;
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%; } }
	.youtube {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		width: 100%;
		padding-top: 45.75%;
		iframe {
			position: absolute;
			top: 0;
			right: 0;
			width: 100% !important;
			height: 100% !important; } }
	@include media(lg) {
		.googlemap {
			padding-top: 50%; }
		.youtube {
			padding-top: 56.25%; } }
	@include media(md) {
		margin: $sectionMargin*.2 auto 0;
		overflow: hidden;
		h1,h2,h3,h4,h5,h6 {
			margin-bottom: 2rem; }
		h1 {
			font-size: 2.2rem;
			padding-bottom: 10px;
			&::before {
				width: 100px; } }
		h2 {
			font-size: 2.0rem;
			&::before {
				width: 50px; } }
		h3 {
			font-size: 1.8rem;
			padding-bottom: 5px; }
		h4 {
			font-size: 1.6rem;
			padding-bottom: 5px; }
		h5 {
			font-size: 1.4rem;
			padding-bottom: 5px; }
		h6 {
			font-size: 1.4rem;
			padding-bottom: 5px; } } }
