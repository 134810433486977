.l-main-information {
	position: relative;
	padding: 50px 20px 200px;
	width: 1150px;
	max-width: 100%;
	margin: 0px auto;

	.info-item {
		border-bottom: 1px solid #54A868;
		padding-bottom: 20px;
		margin-bottom: 50px;

		p {
			float: left; }

		.info-date {
			color: white;
			background-color: #54A868;
			padding: 5px 15px;
			border-radius: 20px;
			font-size: 16px;
			font-family: 'Fredoka One', cursive;
			text-align: center;
			margin-right: 20px; }

		.info-arrow img:hover {
			transform: scale(1.2);
			transform-origin: center;
			transition: all .3s; }

		.info-category {
			color: #54A868;
			font-size: 20px;
			padding-top: 5px;

			.c-title {
				font-family: 'Fredoka One', cursive; } }

		.info-help {
			font-size: 18px;
			line-height: 1.7;
			color: #000000; } }

	.info-menu-box {
		border: 1px solid #54A868;
		border-radius: 6px;
		box-shadow: 6px 6px 2px 1px #54A868;
		padding: 30px;
		margin-bottom: 50px;

		.c-title {
			font-weight: bold;
			font-size: 22px;
			text-align: center;
			font-family: 'Fredoka One', cursive;
			margin-bottom: 30px; }

		ul {
			list-style: none;

			li {
				border-bottom: 1px solid #707070;
				padding: 15px;

				a {
					font-size: 16px;
					color: #3f3f3f; } } } }

	.info-detail {
		.info-date {
			color: white;
			background-color: #54A868;
			padding: 5px 15px;
			border-radius: 20px;
			font-size: 16px;
			font-family: 'Fredoka One', cursive;
			text-align: center;
			margin-right: 20px; }

		.info-category {
			color: #54A868;
			font-size: 20px;
			padding-top: 5px; }

		.txt-title {
			font-size: 20px;
			font-weight: bold;
			border-bottom: 1px solid #54A868;
			padding-bottom: 10px;
			margin: 30px 0px; }

		.info-content {
			width: 100%; }

		.info-image {
			width: 90%;
			border-radius: 20px;
			height: 300px;
			background-color: #ebebeb;
			margin-left: 5%; }

		.info-txt-help {
			margin-top: 40px;
			font-size: 16px;
			line-height: 28px; }

		.video-title {
			font-size: 20px;
			color: #54A868;
			font-weight: bold;
			margin: 50px 0px 50px; }

		.info-video {
			width: 100% !important;
			border-radius: 20px;
			margin-bottom: 30px;
			height: auto;
			background-size: cover; }

		.info-map {
			width: 100%;
			height: 400px;

			iframe {
				border-radius: 20px; } } } }

/* detail */

.wrapper {
	display: table;
	position: relative;
	width: 100%; }

.playpause {
	background-image: url(../images/park/info/info-video-play.png);
	background-repeat: no-repeat;
	position: absolute;
	width: 80px;
	height: 50px;
	left: 0%;
	right: 0%;
	top: 0%;
	bottom: 0%;
	margin: auto;
	opacity: 0.8;
	background-size: contain;
	background-position: center; }

@media screen and (max-width: 767px) {
	.l-main-information {
		padding-top: 0px !important; } }
