/*
 *
 *TemplateMo 557 Grad School
 *
 *https: *///templatemo.com/tm-557-grad-school

/* -------------------------
 * import
 * ------------------------- */
@import "park/_bootstrap.min";
@import "park/_animation";
@import "park/plugin/fontawesome";
@import "park/plugin/owl";
@import "park/plugin/lightbox";
@import "park/plugin/slick";
@import "park/plugin/slick-theme";
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Kosugi+Maru&family=Titan+One&display=swap');

body {
  font-family: 'Kosugi Maru', sans-serif; }

/* Basic */

a {
  color: #3CF;
  text-decoration: none;

  &:hover {
    color: #FC3;
    text-decoration: none; } }

ul {
  padding: 0px;
  margin: 0px;
  list-style: none; }

p {
  font-size: 13px;
  line-height: 1.6; }

.section-heading {
  text-align: center;
  margin-bottom: 70px;

  h2 {
    &:before {
      width: 1px;
      height: 100px;
      background-color: rgba(250, 250, 250, 0.1);
      position: absolute;
      top: -102px;
      content: '';
      left: 50%;
      transform: translate(-50%); }

    margin-top: 100px;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 15px 20px;
    border: 2px solid rgba(250, 250, 250, 0.1); } }

/* Buttons */

.main-button a {
  background-color: #f5a425;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 12px 20px;
  display: inline-block;
  outline: none; }

a.menu-link {
  display: none; }

.menu-close {
  display: none !important; }

nav.main-nav {
  float: left;
  max-width: 36vw; }

// @media screen and (max-width: 950px)
//   a.menu-link
//     float: right
//     display: block
//     font-size: 36px
//     color: #fff
//     padding-right: 30px
//     padding-top: 30px
//
//   nav
//     &.main-nav
//       float: none
//
//     &[role="navigation"]
//       clear: both
//       -webkit-transition: all 0.3s ease-out
//       -moz-transition: all 0.3s ease-out
//       -ms-transition: all 0.3s ease-out
//       -o-transition: all 0.3s ease-out
//       transition: all 0.3s ease-out
//
//   .js nav[role="navigation"]
//     overflow: hidden
//     max-height: 0
//
//   nav[role="navigation"]
//     &.active
//       max-height: 20em
//       height: 20em
//       overflow-y: scroll
//
//     ul
//       top: 0
//       width: 100%
//       position: relative
//       margin: 0
//       padding: 0
//       background-color: black
//
//     li
//       a
//         display: block
//         padding: 15px 0px
//
//         /*border-bottom: 1px solid rgba(255,255,255,1.0);
//         text-align: center
//         color: #fff
//         font-size: 13px
//         text-transform: uppercase
//         font-weight: 700
//         line-height: 1.5
//
//       &:hover a, &.active a
//         color: #f5a425

/* ---------------------------------------------------------
 *	br.is-pc
 *---------------------------------------------------------- */
br.is-pc {
  @media screen and (max-width: 767px) {
    display: none; } }
/* ---------------------------------------------------------
 *	br.is-sp
 *---------------------------------------------------------- */
br.is-sp {
  display: none;
  @media screen and (max-width: 767px) {
    display: block; } }


@media screen and (max-width: 950px) {
  a.menu-link {
    float: right;
    display: block;
    font-size: 36px;
    color: #fff;
    padding-right: 30px;
    padding-top: 10px; }

  nav {
    &.main-nav {
      float: none; }

    &[role="navigation"] {
      clear: both;
      transition: all 0.3s ease-out; } }

  .menu-close {
    display: block !important;
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 2.6rem;
    color: #fff; }
  nav[role="navigation"] {
    margin-top: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #77695F;
    transform: translateY(-100vh);
    z-index: 101;
    &.active {
      opacity: 1;
      transform: translateY(0);
      .main-menu {
        float: none;
        margin: 0;
        padding: 60px 40px 40px !important; }
      .header_menu {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        >* {
          flex-basis: 48%;
          display: flex;
          // margin-top: 20px
          a {
            display: flex;
            flex-basis: 100%;
            padding: 25px 0;
            border-bottom: 1px solid #fff;
            font-size: 1.4rem;
            color: #fff; } } } } } }

@media screen and (max-width: 568px) {
  nav[role="navigation"] {
    &.active {
      .main-menu {
        padding: 80px 20px 40px !important; }
      .header_menu {
        display: block;
        >* {
          a {
            padding: 15px 0;
            font-size: 1.0rem;
            font-weight: normal; } } } } } }

@media screen and (min-width: 951px) {
  .js nav[role="navigation"] {
    max-height: none; }

  nav[role="navigation"] li {
    display: inline-block;
    margin: 0 0.25em;

    a {
      border: 0; } } }

/* Header */

@media screen and (max-width: 1050px) {
  .main-header .logo {
    padding-left: 30px !important; }

  .main-menu {
    padding-right: 30px !important;
    margin-top: 50px;

    li {
      margin-left: 5px !important; } } }

.main-header {
  background: url('../images/park/common/header_bg.png') repeat bottom center;
  width: 100%;
  height: 150px;
  position: fixed;
  z-index: 112;
  top: 0;

  .logo {
    float: left;
    line-height: 80px;
    padding-left: 40px;
    margin-top: 10px;

    .help {
      font-size: 14px;
      color: white;
      padding-top: 10px;
      margin-bottom: 0px !important; }

    a {
      font-size: 36px;
      text-transform: uppercase;
      font-weight: 800;
      color: #fff;

      em {
        font-style: normal;
        color: #f5a425; } } } }

.main-menu {
  float: right;
  padding-left: 40px;
  margin-top: 40px;

  li {
    display: inline-block;
    line-height: 1.8;
    margin-left: 5px;
    position: relative;

    &:first-child {
      margin-left: 0px; }

    a {
      padding: 5px;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: bold;
      color: #fff;
      border: 2px solid transparent;
      transition: all 0.5s; }

    &.has-submenu {
      a:after {
        content: '\f107';
        font-family: "FontAwesome";
        margin-left: 5px; }

      ul li a:after {
        display: none; } }

    .sub-menu {
      position: absolute;
      width: 160px;
      background-color: #18233a;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s; }

    &:hover .sub-menu {
      opacity: 1;
      visibility: visible; }

    .sub-menu li {
      display: block;
      line-height: 20px;
      margin-left: 0px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #121b2f;

      &:first-child {
        padding-top: 15px; }

      &:last-child {
        margin-bottom: 0px;
        border-bottom: none; }

      a {
        font-size: 12px;
        font-weight: 500;
        padding: 0px 15px;
        letter-spacing: 0.5px;
        border: none;
        transition: all 0.5s;

        &:hover {
          color: #f5a425;
          border: none; } } } } }

.main-nav li {
  &:hover a, &.active a {
    color: orangered; } }

@media (max-width: 950px) {
  .main-nav li {
    &:hover a, &.active a {
      border: 2px solid transparent;
      border-bottom: 1px solid rgba(250, 250, 250, 0.25); } } }

@media (min-width: 950px) and (max-width: 1450px) {
  .main-header #reserve {
    transform: scale(0.8);
    transform-origin: top right; }

  .bg-banner {
    min-width: 0;
    min-height: 0; }

  .main-menu {
    padding-left: 0px;
    margin-left: -80px;

    li {
      margin-left: 0px; } }

  .main-header .logo {
    transform: scale(0.8);
    transform-origin: top left; } }

/* Slider */

.main-banner {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-top: 120px;


  @media screen and (max-width: 950px) {
    margin-top: 76px; } }
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 7px;
  width: 100%;
  z-index: 1; }

.deco-top {
  position: absolute;
  top: 20%;
  width: 100%; }

.deco-bottom {
  position: absolute;
  bottom: 0;
  width: 100%; }

.main-banner .caption {
  text-align: left;
  position: absolute;
  left: 35%;
  top: 75%;
  transform: translate(-50%, -50%);

  h6 {
    margin-top: 0px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    color: #543C30;
    letter-spacing: 1px;
    background-color: white;
    padding: 10px 0px; } }

@media screen and (max-width: 1180px) {
  .main-banner .caption h6 {
    font-weight: 500; } }

@media screen and (max-width: 767px) {
  .main-banner .caption h6 {
    font-weight: 500; } }

.content-hide {
  margin-top: 15px;
  display: none;

  p {
    color: #fff; } }

@media screen and (max-width: 767px) {
  .main-header {
    background: url('../images/park/common/header_bg_sp.png') repeat bottom center;
    min-height: 100px; }

  .main-banner {
    margin-top: 70px; }

  a.menu-link {
    padding-top: 15px;
    padding-right: 15px; }

  .features-content {
    padding: 15px;

    h4 {
      i {
        display: block;
        margin-bottom: 10px; }

      font-size: 14px; }

    p {
      display: none;

      &.hidden-sm {
        display: block; } }

    a {
      letter-spacing: 0px;
      font-size: 13px;
      font-weight: 600; } } }

/* Magnific Popup CSS */

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; } }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;

  .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; } }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;

  a {
    color: #CCC;

    &:hover {
      color: #FFF; } } }

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none; }

button {
  &.mfp-close, &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation; }

  &::-moz-focus-inner {
    padding: 0;
    border: 0; } }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;

  &:hover, &:focus {
    opacity: 1; }

  &:active {
    top: 1px; } }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;

  &:active {
    margin-top: -54px; }

  &:hover, &:focus {
    opacity: 1; }

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }

  &:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; } }

.mfp-arrow-left {
  left: 0;

  &:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }

  &:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; } }

.mfp-arrow-right {
  right: 0;

  &:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }

  &:before {
    border-left: 27px solid #3F3F3F; } }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;

  .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }

  .mfp-close {
    top: -40px; } }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; } }

/* Main image in popup */

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */

.mfp-figure {
  line-height: 0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }

  small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }

  figure {
    margin: 0; } }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   *   * Remove all paddings around the image on small screen */

  .mfp-img-mobile {
    .mfp-image-holder {
      padding-left: 0;
      padding-right: 0; }

    img.mfp-img {
      padding: 0; }

    .mfp-figure {
      &:after {
        top: 0;
        bottom: 0; }

      small {
        display: inline;
        margin-left: 5px; } }

    .mfp-bottom-bar {
      background: rgba(0, 0, 0, 0.6);
      bottom: 0;
      margin: 0;
      top: auto;
      padding: 3px 5px;
      position: fixed;
      box-sizing: border-box;

      &:empty {
        padding: 0; } }

    .mfp-counter {
      right: 5px;
      top: 3px; }

    .mfp-close {
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      text-align: center;
      padding: 0; } } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Footer */

footer {
  background-color: #152036;
  text-align: center;

  p {
    margin-bottom: 0px;
    padding: 25px 0px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;

    a {
      color: #f5a425;

      &:hover {
        color: #f5a425; } } } }

@media screen and (max-width: 992px) {
  .features {
    background-color: #0c1228; }

  .features-content {
    position: relative; }

  .features-post {
    border-left: 0; } }

@media screen and (max-width: 950px) {
  .main-menu {
    padding-right: 0 !important; }

  .main-header .logo {
    line-height: 73px; }

  .main-menu li {
    display: block;
    line-height: 1;
    margin-left: 0 !important;

    .sub-menu {
      opacity: 1;
      visibility: visible;
      position: static;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      li:last-child {
        border-bottom: 1px solid rgba(250, 250, 250, 0.25); } } } }

@media screen and (max-width: 950px) and (max-height: 400px) {
  nav[role="navigation"].active {
    max-height: calc(80px + 100vh);
    overflow-y: auto; } }

@media screen and (max-width: 767px) {
  footer p {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.25px;
    padding: 25px 0px 60px; } }


@import "park/page/top";
@import "park/page/access";
@import "park/page/clinic";
@import "park/page/information";
@import "park/page/service";
@import "park/page/staff";

// 初期設定
@import "../../../_base/variable";
@import "C:/htdocs/visca-japan/develop/_sass/_tool/mixin/_background.scss";
@import "C:/htdocs/visca-japan/develop/_sass/_tool/mixin/_icon.scss";
@import "C:/htdocs/visca-japan/develop/_sass/_tool/mixin/_utility.scss";
$baseWidth: 950px;
$keyColor: #54a868;
// ★標準ボーダーカラー
$baseBorderColor: #d8d1c8;
// ★ボタンカラー
$baseButtonColor: $keyColor;
$baseButtonColorHover: $keyColor;
$baseButtonAnchorTextColor: $keyColor;
$baseButtonAnchorBorderColor: $keyColor;
$baseButtonAnchorBgColor: #FFFFFF;
$baseButtonAnchorBgColorHover: lighten($keyColor, 80%);

@import "park/page/single-wiziwig";
@import "park/page/c-layout_pattern";
@import "park/page/c-mwform";

@import "add_park_yoshida";
