/************************************** service-top  ****************************** */

.l-service-top {
	position: relative;
	background: url(../images/park/service/service_menu_bg_pc.png) top center;
	padding: 100px 0px 10px 0px;
	width: 100%;
	max-width: 100%;
	min-height: 80vh;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -18vh;

	#service-menu {
		width: 1050px;
		max-width: 100%;
		padding: 10px;
		margin: 0px auto; }

	.menu-item {
		background-color: white;
		border: 2px solid #54A868;
		border-radius: 10px;
		width: 22%;
		height: 65px;
		box-shadow: 3px 3px #54A868;
		float: left;
		margin-right: 3%;
		margin-top: 20px;
		cursor: pointer;

		&:hover {
			transform-origin: center;
			transform: scale(1.1);
			transition: all .3s; }

		a {
			padding: 0.8em 1em 1em 1em;
			text-decoration: none !important;
			font-size: 18px;
			display: block;
			color: #000000; }

		p {
			float: left;
			font-size: 15px;
			padding-top: 0.5rem; }

		img {
			float: left;
			margin-left: 0;
			margin-right: 0.5rem; } } }

/************************************** service-content  ****************************** */

.l-service-content {
	position: relative;
	width: 100%;
	background-color: white;
	padding: 100px 0px;

	.service-item-content {
		position: relative;
		width: 950px;
		max-width: 100%;
		margin: 30px auto !important;
		.service-item-icon img {
			max-width: 120px; }
		.c-item-title {
			font-size: 36px;
			margin-top: 20px;
			&.is-free {
				color: #FF8266;
				>span {
					color: #FF8266;
					font-size: 20px; } } }
		.c-item-icon {
			max-width: 120px; }
		.c-item-help {
			font-size: 26px;
			margin-top: 40px;
			color: #FF8266; }

		.c-detail-list {
			list-style: none;

			li {
				line-height: 27px;
				font-size: 20px;
				color: #FF8266;
				&:before {
					content: "●";
					color: #FF8266;
					font-size: 12px;
					font-weight: bold;
					display: inline-block;
					margin-left: -1em;
					margin-right: 5px; } } }
		.c-detail-help {
			font-size: 16px;
			margin-top: 30px;
			line-height: 2;
			margin-left: -30px; }

		.item-column {
			position: relative;
			border-radius: 10px;
			border: 2px solid #54A868;
			box-shadow: 5px 5px #54A868;
			padding: 30px;
			margin: 50px 0px 150px 0px;

			.c-title {
				font-size: 20px;
				color: #54A868;
				margin: 20px 0px; }

			.c-help {
				font-size: 16px; }

			.c-item-mark {
				position: absolute;
				top: -3px;
				left: 15px;
				width: 70px;
				height: auto; } } }

	.service-deco1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 20%; }

	.service-deco2 {
		position: absolute;
		top: 35%;
		right: 2%;
		width: 10%; }

	.service-deco3 {
		position: absolute;
		top: 35%;
		left: 2%;
		width: 10%; }

	.service-deco4 {
		position: absolute;
		top: 0;
		right: 0;
		width: 20%; }

	.service-deco5 {
		position: absolute;
		top: 0;
		left: 0;
		width: 20%; }

	.service-deco6 {
		position: absolute;
		top: 35%;
		right: 3%;
		width: 8%; }

	.service-deco7 {
		position: absolute;
		top: 35%;
		left: 3%;
		width: 8%; }

	.service-deco8 {
		position: absolute;
		top: 0;
		right: 0;
		width: 20%; }

	.service-deco9 {
		position: absolute;
		top: 0;
		left: 0;
		width: 20%; }

	.service-deco10 {
		position: absolute;
		top: 30%;
		right: 2%;
		width: 10%; }

	.service-deco11 {
		position: absolute;
		top: 35%;
		left: 3%;
		width: 8%; }

	.service-deco12 {
		position: absolute;
		top: 0;
		right: 0;
		width: 20%; } }

/************************************** service-content  ****************************** */

@media screen and (max-width: 767px) {
	.l-service-top {
		background: url(../images/park/service/service_menu_bg_sp.png) no-repeat;
		background-size: 100% 100%;
		margin-top: -20vh;
		min-height: 100%;
		padding: 100px 0px 100px 0px;

		#service-menu {
			margin-top: -50px;
			padding-left: 20px; } }

	.l-service-content {
		padding-top: 20px;

		.service-item-content {
			.c-detail-help {
				margin-left: 20px; }

			.c-detail-list {
				margin: 20px 0px 0px 50px; } } }

	.l-service-top .menu-item {
		width: 30%;

		p {
			float: none;
			font-size: 11px;
			padding-top: 0px !important;
			overflow-wrap: normal; }

		img {
			float: none;
			margin-left: 22%;
			transform: scale(0.7);
			margin-top: -15px; }

		.s1 {
			margin-left: 18% !important;
			margin-top: 0px !important; }

		a {
			text-align: center;
			padding-left: 0px;
			padding-right: 0px; } }

	.l-service-content {
		.service-deco2, .service-deco3, .service-deco6, .service-deco7, .service-deco10, .service-deco11 {
			top: 5%; } }

	.item-column .item-column-image {
		width: 100%; }

	.l-service-content .service-item-content {
		.item-column {
			padding: 20px 10px; }

		.c-item-title {
			font-size: 26px; }

		.c-item-help {
			font-size: 18px; } } }

@media screen and (min-width: 768px) and (max-width: 950px) {
	.l-service-top {
		min-height: 100vh;

		.menu-item {
			width: 30%;

			p {
				font-size: 13px; } }

		#service-menu {
			padding-left: 40px;
			padding-right: 40px; } }

	.item-column .item-column-image {
		width: 100%; }

	.l-service-top .menu-item img {
		margin-left: 0; }

	.l-service-content {
		.service-deco2, .service-deco3, .service-deco6, .service-deco7, .service-deco10, .service-deco11 {
			top: 5%; } } }
