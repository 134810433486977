@charset 'utf-8';

$path: '/images';

// フォントサイズ
$fontFamilyGothicDefault: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo, sans-serif;
$fontFamilyMinchoDefault: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Noto Serif JP", serif;

// フォントサイズ
$fontSize2S: 1.2rem;
$fontSizeS: 1.4rem;
$fontSizeM: 1.6rem;

// 行間
$lineHeightM: 1.6;

// section Margin
$sectionMargin: 100px;
$mediamGutter: 30px;
$smallGutter: 15px;

// SNSカラー
$snsColorMap: (facebook : #3a589a,	twitter : #00aaf0,	googleplus: #dd4b39,	line : #00c300);

// ページの横幅
$baseWidth: 1180px;
$baseWidthWide: 1240px;
$baseSideColumn: 300px;
$sm       : 375px;
$md       : 768px;
$lg       : 1023px;
$xl       : 1215px;
$xxl      : 1444px;

// ブレイクポイント
$breakpointMap: ('xxl': $xxl, 'xl': $xl, 'lg': $lg, 'md': $md, 'sm': $sm);

// z-index
$zIndex: btnPageTop, btnTel, chicMainvisual, header, slideMenu, message, floatingMenu, btnfloatingMenuClose, drawerMenuOverlay, btnDrawerMenuClose, drawerMenu;

// アイコンフォント
$fontMap: ( home:'e800', check:'e801', phone:'e802', mobile:'e803', line:'e807', twitter:'f099', facebook:'f09a', angle-double-left:'f100', angle-double-right:'f101', angle-left:'f104', angle-right:'f105', instagram:'f16d');
