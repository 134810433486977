@charset "utf-8";

$select: '#main';

body#p-second:not(.p-access):not(.p-services):not(.p-facilities):not(.p-staff) {
  .l-footer-reserve {
    margin-top: 0; } }

#{$select} {
  margin-top: 0 !important; }
/* ---------------------------------------------------------
 *	wp-block-uagb-advanced-heading ※タイトル
 *---------------------------------------------------------- */
#{$select} .wp-block-uagb-advanced-heading {
  text-align: center; }
/* ---------------------------------------------------------
 *	uagb-heading-text ※タイトル
 *---------------------------------------------------------- */
#{$select} h2.uagb-heading-text {
  font-size: 2.6rem;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  color: $keyColor;
  @include media(md) {
    font-size: 5vw; }
  @include media(sm) {
    font-size: 7vw; } }
/* ---------------------------------------------------------
 *	uagb-desc-text ※テキスト
 *---------------------------------------------------------- */
#{$select} .uagb-desc-text {
  margin-top: $sectionMargin*.5;
  font-size: 1.4rem;
  @include media(md) {
    margin-top: $sectionMargin*.3; }
  @include media(sm) {
    font-size: 1.0rem; } }

/* ---------------------------------------------------------
 *	wp-block-buttons ※ボタンブロック
 *---------------------------------------------------------- */
#{$select} .wp-block-buttons {
  margin-top: $sectionMargin*.5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media(md) {
    // margin-left: -$smallGutter
    // margin-right: -$smallGutter
    margin-top: $sectionMargin*.3;
    font-size: 3.8vw; } }
/* ---------------------------------------------------------
 *	wp-block-button ※ボタン
 *---------------------------------------------------------- */
#{$select} .wp-block-button {
  flex-basis: 23%;
  margin: 0 0 20px 0;
  display: flex;
  &:not(:nth-of-type(4n-3)) {
    margin-left: 2.6%; }
  @include media(lg) {
    flex-basis: 31%;
    &:not(:nth-of-type(4n-3)) {
      margin-left: 0%; }
    &:not(:nth-of-type(3n-2)) {
      margin-left: 3.5%; } }
  @include media(md) {
    flex-basis: 48%;
    margin: 0 0 10px 0;
    &:not(:nth-of-type(3n-2)) {
      margin-left: 0%; }
    &:nth-of-type(even) {
      margin-left: 3%; } } }

/* ---------------------------------------------------------
 *	wp-block-image ※image
 *---------------------------------------------------------- */
#{$select} .wp-block-image {
  border-radius: 15px;
  overflow: hidden; }
/* ---------------------------------------------------------
 *	wp-block-button__link ※ボタンリンク
 *---------------------------------------------------------- */
#{$select} .wp-block-button__link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  padding: 15px 40px 15px 20px;
  transition: all .3s;
  color: $baseButtonAnchorTextColor;
  font-size: 1.2rem;

  background-color: $baseButtonAnchorBgColor;
  border: 2px solid $baseButtonAnchorBorderColor;
  border-radius: 10px;
  box-shadow: 3px 3px #54a868;
  @include icoArrow(before, 50%, 20px, 50%, null, 0.8rem, 2px, $baseButtonAnchorTextColor, 135deg);
  &::before {
    transition: all .3s; }
  &.is-hover,&:hover {
    background-color: $baseButtonAnchorBgColorHover;
    transform: scale(1.1);
    &::before {
      top: 60%; } }
  @include media(sm) {
    font-size: 3.8vw; } }
/* ---------------------------------------------------------
 *	.wp-block-uagb-section ※セクション
 *---------------------------------------------------------- */
#{$select} .wp-block-uagb-section {
  padding: $sectionMargin 0;
  max-width: none;
  line-height: 1.4;
  p {
    line-height: 1.4; }
  // padding: 0
  &:nth-of-type(even) {
    // background-color: $baseBackgroundColor
    // width: 100%
    // max-width: 100%
    // min-height: 100vh
    background-image: url(../images/park/common/bg_mount.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    text-align: center;
    padding: 150px 0;
    position: relative; }
  @include media(lg) {
    // margin-left: -$mediamGutter
    // margin-right: -$mediamGutter
    padding: $sectionMargin $mediamGutter;
    &:nth-of-type(even) {
      background-image: url(../images/park/common/bg_mount_sp.png);
      padding: $sectionMargin $mediamGutter; } }
  @include media(md) {
    // margin-top: $sectionMargin*.5
    // margin-left: -$smallGutter
    // margin-right: -$smallGutter
    padding: $sectionMargin*.5 $smallGutter;
    &:nth-of-type(even) {
      padding: $sectionMargin*.5 $smallGutter; } } }
/* ---------------------------------------------------------
 *	.uagb-section__inner-wrap ※セクション 要素
 *---------------------------------------------------------- */
#{$select} .uagb-section__inner-wrap {
  max-width: $baseWidth;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
  p,figure {
    margin-top: $sectionMargin*.2;
    font-size: 1.0rem;
    line-height: 1.6; }

  ul {
    margin-top: $sectionMargin*.2;
    li {
      position: relative;
      padding-left: 2rem;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: left;
      // color: #fff
      & + li {
        margin-top: 10px; }
      &::before {
        content: '●';
        position: absolute;
        top: 0;
        left: 0;
        color: #FF8266; } } }
  @include media(md) {
    p {
      text-align: left; }
    ul {
      li {
        font-size: 1.2rem; } } } }
/* ---------------------------------------------------------
 *	.wp-block-media-text ※メディアブロック
 *---------------------------------------------------------- */
#{$select} .wp-block-media-text {
  margin-top: $sectionMargin*.5;
  @include media(md) {
    margin-top: $sectionMargin*.2; } }
/* ---------------------------------------------------------
 *	.wp-block-media-text__media ※メディアブロック img
 *---------------------------------------------------------- */
#{$select} .wp-block-media-text__media {
  border-radius: 15px;
  overflow: hidden; }
/* ---------------------------------------------------------
 *	.wp-block-media-text__content ※メディアブロック
 *---------------------------------------------------------- */
#{$select} .wp-block-media-text__content {
  @include media(max, 600px) {
    padding: 0; } }

/* ---------------------------------------------------------
 *	.uagb-separator-wrap ※セパレーター　枠
 *---------------------------------------------------------- */
#{$select} .uagb-separator-wrap {
  // margin-top: $sectionMargin*.3
  display: none;
  & + .uagb-desc-text {
    margin-top: $sectionMargin*.5; }
  @include media(md) {
    & + .uagb-desc-text {
      margin-top: $sectionMargin*.4; } } }
/* ---------------------------------------------------------
 *	.uagb-separator ※セパレーター　子
 *---------------------------------------------------------- */
#{$select} .uagb-separator {
  border-color: $keyColor; }
/* ---------------------------------------------------------
 *	.wp-block-uagb-icon-list ※アイコン付きリスト 枠
 *---------------------------------------------------------- */
#{$select} .wp-block-uagb-icon-list {
  margin-top: $sectionMargin*.3; }
#{$select} .uagb-icon-list__outer-wrap .uagb-icon-list__wrapper {
  margin-left: 0;
  margin-right: 0;
  display: block; }
#{$select} .uagb-icon-list__outer-wrap .uagb-icon-list__wrap {
  display: block; }
#{$select} .uagb-icon-list__outer-wrap .uagb-icon-list__wrap .uagb-icon-list__content-wrap {
  display: flex;
  align-items: center; }
/* ---------------------------------------------------------
 *	.uagb-icon-list__wrapper ※アイコン付きリスト　枠2
 *---------------------------------------------------------- */
#{$select} .uagb-icon-list__outer-wrap .uagb-icon-list__source-icon svg {
  width: 20px; }
#{$select} .uagb-icon-list__wrapper {
  margin-bottom: 0 !important;
  font-size: 1.8rem; }

/* ---------------------------------------------------------
 *	.wp-block-uagb-icon-list-child ※アイコン付きリスト　子
 *---------------------------------------------------------- */
#{$select} .wp-block-uagb-icon-list-child {
  margin-bottom: 0 !important;
  & + .wp-block-uagb-icon-list-child {
    margin-top: 10px; } }
/* ---------------------------------------------------------
 *	.uagb-icon-list__label ※アイコン付きリスト　子span
 *---------------------------------------------------------- */
#{$select} .uagb-icon-list__label {
  font-size: 1.4rem;
  font-weight: bold; }

/* ---------------------------------------------------------
 *	.wp-block-table ※アイコン付きリスト　子span
 *---------------------------------------------------------- */
#{$select} .wp-block-table {
  border-bottom: none;
  table {
    table-layout: fixed;
    border-spacing: 10px 0;
    border-collapse: separate;
    border: none;
    tr:nth-child(odd) {
      background-color: #54A868;
      color: #fff; }
    th {
      text-align: center; }
    td,th {
      border-top: 1px solid #54A868;
      padding: 20px 10px;
 } }      // border-bottom: 1px solid $baseBorderColor
  @include media(xxl);
  @include media(xl);
  @include media(lg);
  @include media(md) {
    table {
      border-spacing: 3px;
      td,th {
        padding: 10px 5px;
        font-size: 0.8rem; } } } }
/* ---------------------------------------------------------
 *	.p-flowchart ※フローチャート
 *---------------------------------------------------------- */
#{$select} .p-flowchart {
  .wp-block-media-text {
    padding: 0 20px 20px 20px;
    counter-increment: cnt;
    position: relative;
    z-index: 0;
    background-color: rgba(#fff, .1);
    &::before {
      position: absolute;
      z-index: 1;
      top: -60px;
      left: -100px;
      content: '';
      width: 186px;
      height: 178px;
      background-repeat: no-repeat;
      background-size: 100%; } }
  .wp-block-group .wp-block-media-text {
    &:nth-of-type(1)::before {
      background-image: url(../images/park/free/ico_step-01.png); }
    &:nth-of-type(2)::before {
      background-image: url(../images/park/free/ico_step-02.png); }
    &:nth-of-type(3)::before {
      background-image: url(../images/park/free/ico_step-03.png); }
    &:nth-of-type(4)::before {
      background-image: url(../images/park/free/ico_step-04.png); }
    &:nth-of-type(5)::before {
      background-image: url(../images/park/free/ico_step-05.png); }
    &:nth-of-type(6)::before {
      background-image: url(../images/park/free/ico_step-06.png); }
    &:nth-of-type(7)::before {
      background-image: url(../images/park/free/ico_step-07.png); }
    &:nth-of-type(8)::before {
      background-image: url(../images/park/free/ico_step-08.png); }
    &:nth-of-type(9)::before {
      background-image: url(../images/park/free/ico_step-09.png); }
    &:nth-of-type(10)::before {
      background-image: url(../images/park/free/ico_step-10.png); } }
  @include media(lg) {
    .wp-block-media-text {
      padding: 0 80px 20px 20px;
      &::before {
        font-size: 5rem; }
      &::after {
        top: -40px; } } }
  @include media(md) {
    .wp-block-group {
      margin-top: 80px; }
    .wp-block-media-text {
      margin-top: 80px;
      padding: 0 20px 20px;
      display: block;
      &::before {
        top: -60px;
        left: -20px;
        width: 93px;
        height: 89px; } }
    .wp-block-media-text__content {
      margin-top: 20px; } } }
/* ---------------------------------------------------------
 *	.p-verticalFlat ※フラットレイアウト　縦Ver
 *---------------------------------------------------------- */
#{$select} .p-verticalFlat {
  .wp-block-media-text__content {
    h3,h4 {
      font-size: 1.6rem; }
    p {
      font-size: 1.4rem; } }
  .wp-block-media-text {
    padding: 0 20px 20px;
    // background-color: rgba(#fff, .1)
    grid-template-columns: 40% auto !important; }
  @include media(md) {
    .wp-block-media-text__content {
      margin-top: 20px;
      h3,h4 {
        font-size: 1.2rem; }
      p {
        font-size: 1rem; } }
    .wp-block-media-text {
      grid-template-columns: auto auto !important; } } }
/* ---------------------------------------------------------
 *	.p-horizontalFlat ※フラットレイアウト 横Ver
 *---------------------------------------------------------- */
#{$select} .p-horizontalFlat {
  .wp-block-columns {
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
      content: "";
      display: block;
      width: 31%;
      height: 0; } }
  .wp-block-column {
    flex-basis: 31%;
    flex-grow: 0;
    margin-bottom: 40px;
    &:not(:first-child) {
      margin-left: 0; } }
  .wp-block-column {
    padding: 40px 20px;
    background-color: rgba(#fff, .1);
    h3,h4 {
      font-size: 2.6rem;
 }      // color: #fff

    p {
      font-size: 1.6rem; } }
  @include media(md) {
    .wp-block-column {
      flex-basis: 48%;
      padding: 20px;
      margin-top: 20px;
      h3,h4 {
        // padding-bottom: 30px
        font-size: 2.0rem; } } } }
