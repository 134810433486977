/*
 * jQuery FlexSlider v2.7.1
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 * */
/* ====================================================================================================================
 * FONT-FACE
 * ==================================================================================================================== */

a {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		transition: all .3s; } }

.bg-yellow {
	background-color: #fff8eb; }

/*********************** button *************************** */

.btn {
	background: url('../images/park/common/main_btn.png');
	width: 370px;
	height: 66px;
	color: white;
	transform-origin: center;
	line-height: 48px;
	letter-spacing: 2px;
	font-size: 18px;

	&:hover {
		color: white;
		transform: scale(1.1);
		transform-origin: center; } }

.btn-scroll-top {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: #FF8266;
	width: 80px;
	height: 80px;
	padding: 5px 15px;
	color: white;
	text-align: center;
	z-index: 100;
	font-size: 1rem;

	i {
		font-size: 40px; } }

#breadcrumb {
	font-size: 16px; }

// .btn-retest:hover img, .btn-newer:hover img, .btn-retest:hover p, .btn-newer:hover p
//ransform: scale(1.1)
//ransform-origin: center
//ransition: all .3s

/*********************** main-header *************************** */

.main-header #reserve {
	top: 0;

	.item {
		float: right;
		&.btn-reserve {
			max-width: 80px;
			padding: 0.5rem 1rem 0 0; }
		.phone {
			font-size: 32px;
			font-family: 'Fredoka One', cursive;
			// font-weight: bold
			letter-spacing: 1px;
			color: white !important;
			margin-bottom: 5px !important; }

		i {
			color: #FF8266;
			font-size: 20px; }

		.help {
			font-size: 14px;
			color: white !important;
			line-height: 1.3; } } }


.main-banner {
	.slick-slide {
		margin: 0; } }
/*********************** l-banner *************************** */

.l-banner {
	width: 100%;
	max-width: 100%;
	min-height: 380px;
	background: url(../images/park/common/banner_bg.png) no-repeat top center;
	padding-top: 100px;
	text-align: center;
	margin-top: 120px;

	h2 {
		font-size: 48px;
		color: white;
		font-family: 'Fredoka One', 'Kosugi Maru', sans-serif; }

	p {
		color: white; }
	@media screen and (max-width: 767px) {
		background: url(../images/park/common/banner_bg_sp.png) no-repeat top center; } }
.deco {
	opacity: 0;
	z-index: -1; }

/*********************** Cloud animation *************************** */

@keyframes MoveLR {
	0%, 100% {
		transform: translateX(0); }

	50% {
		transform: translateX(1600px); } }

@keyframes MoveRL {
	0%, 100% {
		transform: translateX(1600px); }

	50% {
		transform: translateX(-100px); } }

@keyframes MoveRL1 {
	0%, 100% {
		transform: translateX(1700px); }

	50% {
		transform: translateX(-80px); } }

#cloud1 {
	position: absolute;
	top: 70px;
	left: 0;
	width: 32px;
	height: 11px;
	animation: MoveLR 120s linear infinite;
	z-index: 2; }

#cloud2 {
	position: absolute;
	top: 120px;
	left: 0;
	width: 32px;
	height: 11px;
	animation: MoveRL 120s linear infinite;
	z-index: 2; }

#cloud3 {
	position: absolute;
	top: 100px;
	left: 0;
	width: 32px;
	height: 11px;
	animation: MoveRL1 140s linear infinite;
	z-index: 2; }

/*********************** corona section *************************** */

.l-corona {
	.c-title {
		color: #54A868;
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 1px; }

	.c-subtitle {
		font-size: 16px;
		padding: 10px 20px;
		background-color: #54A868;
		color: white;
		width: 80%;
		margin-left: 10%; }

	.c-help {
		font-size: 16px;
		margin-top: 20px;
		line-height: 1.6; }

	#l-corona-help {
		width: 950px;
		max-width: 100%;
		padding: 10px;
		margin: -100px auto 0px; } }

/*********************** news section *************************** */

.l-news {
	width: 100%;
	max-width: 100%;
	min-height: 100vh;
	background: url('../images/park/top/top_news_bg_pc.png') top center;
	text-align: center;
	padding: 80px 50px 200px 50px;
	position: relative;
	z-index: 0;

	#l-news-rect {
		width: 1050px;
		max-width: 100%;
		padding: 15px;
		margin: 0px auto;

		.c-help {
			font-size: 16px;
			@media screen and (max-width: 767px) {
				line-height: 1.7; } } }

	.c-header-title {
		color: #FF8266;
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 1px;
		margin-top: -65px;
		z-index: 2; }

	#sub-news {
		width: 700px;
		max-width: 100%;
		padding: 0px 10px;
		margin: 20px auto 0; }

	.c-subtitle {
		background-color: #FFF8EB;
		padding: 10px 10px;
		color: #FF8266;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 1px;
		z-index: 2; }

	.l-news-time {
		border: 2px solid #FF8266;
		border-radius: 10px;
		background-color: white;
		padding: 10px 20px;
		margin-top: -30px; }

	.c-block-title {
		position: relative;
		font-size: 28px;
		font-family: 'Fredoka One', cursive;
		color: #54A868;
		background-color: #FFF8EB;
		padding: 10px 20px;
		letter-spacing: 2px;
		margin-top: -20px;
		z-index: 3; }

	.news-bug1 {
		position: absolute;
		top: -2vh;
		right: 5%;
		width: 12%; }

	.news-bug2 {
		position: absolute;
		top: -5vh;
		right: 5%;
		width: 12%; }

	.rect-container {
		position: relative;
		border: 2px solid #54A868;
		border-radius: 10px;
		padding: 5px 0px;
		height: 40vh;
		background-color: white;
		box-shadow: 3px 3px #54A868;
		margin-top: -25px;
		margin-bottom: 30px;
		z-index: 2; }

	.l-news-rect {
		width: 100%;
		height: 100%;
		text-align: left;
		overflow-y: scroll;
		margin-left: -2px;

		&::-webkit-scrollbar {
			width: 8px; }

		&::-webkit-scrollbar-track {
			background: #eeeeee; }

		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			background: #54A868;

			&:hover {
				background: #54A868; } }

		.item {
			padding: 0px 10px;
			border-bottom: 2px solid #54A868; }

		.latest-item h2:after {
			content: "新着情報";
			background-color: #FF8266;
			border-radius: 20px;
			padding: 0px 10px;
			color: white;
			font-size: 13px;
			margin-left: 10px; }

		.notify-item h2:after {
			content: "お知らせ";
			background-color: #F4CF56;
			border-radius: 20px;
			padding: 0px 10px;
			color: white;
			font-size: 13px;
			margin-left: 10px; }

		h2, h3 {
			font-size: 16px; }

		p {
			font-size: 16px;
			line-height: 1.7; } }

	.deco1 {
		position: absolute;
		top: 10%;
		left: 5%;
		width: 10%;
		@media screen and (max-width: 767px) {
			width: 20%;
			top: 2%; } }

	.deco2 {
		position: absolute;
		top: 10%;
		right: 5%;
		width: 10%;
		@media screen and (max-width: 767px) {
			width: 20%;
			top: 1%; } }

	.deco3 {
		position: absolute;
		top: 70%;
		left: 5%;
		width: 10%;
		@media screen and (max-width: 767px) {
			width: 20%;
			top: 43%; } }

	.deco4 {
		position: absolute;
		top: 70%;
		right: 3%;
		width: 10%;
		@media screen and (max-width: 767px) {
			width: 10%;
			top: 83%; } } }
/* width */

/* Track */

/* Handle */

/* Handle on hover */

/*********************** calendar section  *************************** */

.l-calendar {
	position: relative;
	background: url(../images/park/footer/footer_bg.png) top center;
	padding: 20px 0px 200px 0px;
	width: 100%;
	max-width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -15vh;
	z-index: 0;

	.c-title {
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		color: white;
		letter-spacing: 2px;
		margin-bottom: 0px; }

	.c-title-jp {
		font-size: 16px;
		color: #FFE380; } }

.c-title-jp {
	&:before, &:after {
		display: inline-block;
		content: "";
		border-top: 1px solid #FFE380;
		width: 3rem;
		margin: 12px;
		transform: translateY(0.5rem); } }

.l-calendar {
	#gg-calendar {
		width: 700px;
		height: 420px;
		max-width: 100%;
		background-color: white;
		margin: 30px auto 0px; }

	.cal-tree1 {
		position: absolute;
		width: 15%;
		top: 0;
		left: 0;
		@media screen and (max-width: 767px) {
			width: 20%; } }

	.cal-tree2 {
		position: absolute;
		width: 15%;
		top: 2%;
		right: 0;
		@media screen and (max-width: 767px) {
			width: 20%; } }

	.cal-bug1 {
		position: absolute;
		top: 25%;
		left: 13%; }

	.cal-bug2 {
		position: absolute;
		top: 55%;
		right: 5%; }

	.cal-grass1 {
		position: absolute;
		top: 30%;
		right: 0;
		width: 20%; }

	.cal-grass2 {
		position: absolute;
		top: 70%;
		left: 0;
		width: 20%; }

	.cal-bird {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 14%;
		z-index: 2; }

	.c-subtitle {
		color: white;
		font-size: 24px;
		letter-spacing: 1px;
		font-weight: bold;
		@media screen and (max-width: 767px) {
			font-size: 6vw; } }

	.c-title-support {
		color: white;
		font-size: 32px;
		letter-spacing: 1px;
		line-height: 1.2; }

	.c-help-support {
		color: white;
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 1.7;
		@media screen and (max-width: 767px) {
			text-align: left; } } }

/*********************** greeting *************************** */

.l-greeting {
	position: relative;
	background: url(../images/park/top/top_greeting_bg_pc.png) top center;
	padding: 20px 0px 100px 0px;
	width: 100%;
	max-width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -15vh;
	z-index: 0;

	.c-title {
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		color: #54A868;
		letter-spacing: 2px;
		margin-bottom: 0px; }

	.c-title-jp {
		font-size: 16px;
		color: #543C30;

		&:before, &:after {
			border: 1px solid #543C30; } } }

rt {
	font-size: 11px;
	transform: translateY(-5px); }

.c-manager-name {
	position: relative;
	font-size: 22px;
	margin-top: 50px;
	z-index: 5; }

#greeting {
	width: 1050px;
	margin: 0px auto;
	max-width: 100%;
	padding: 10px;

	.photo-image {
		position: relative;
		box-shadow: -20px 20px 2px 1px #FFECC8;
		border-radius: 10px;
		max-width: 100%;
		z-index: 5; }

	.c-subtitle {
		font-size: 24px;
		font-weight: bold;
		line-height: 1.6; }

	.c-help {
		font-size: 16px;
		background-color: rgba(255,255,255, 0.8); }

	.c-title-career {
		font-size: 18px;
		font-weight: bold;
		color: #54A868;
		background-color: white;
		padding: 10px 20px; }

	.l-career-rect {
		background-color: white;
		border-radius: 10px;
		border: 2px solid #54A868;
		margin-top: -30px; } }

.l-greeting {
	.greet-deco-1 {
		position: absolute;
		top: 20%;
		left: 2%;
		width: 18%;
		@media screen and (max-width: 767px) {
			width: 36%;
			top: 8%; } }

	.greet-deco-2 {
		position: absolute;
		bottom: 10%;
		right: -1%;
		width: 18%;
		@media screen and (max-width: 767px) {
			width: 36%;
			bottom: 4%;
			left: 0%; } }

	.greet-child-2 {
		position: absolute;
		top: 30%;
		right: 0%;
		width: 12%;
		@media screen and (max-width: 767px) {
			width: 18%;
			top: 35%;
			right: -1%; } }

	.greet-child-1 {
		position: absolute;
		bottom: 5%;
		left: 15%;
		width: 12%;
		@media screen and (max-width: 767px) {
			width: 24%; } }

	.greet-tree-1 {
		position: absolute;
		top: 50%;
		left: 7%;
		width: 5%;
		@media screen and (max-width: 767px) {
			width: 10%;
			top: 36%; } }

	.greet-tree-2 {
		position: absolute;
		top: 15%;
		right: 20%;
		width: 3%;
		@media screen and (max-width: 767px) {
			width: 6%; } } }

/*********************** section l-features *************************** */

.l-features {
	position: relative;
	background: url(../images/park/top/top_news_bg_pc.png) top center;
	padding: 40px 0px 130px 0px;
	width: 100%;
	max-width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -5vh;

	.c-title {
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		color: #54A868;
		letter-spacing: 2px;
		margin-bottom: 0px; }

	.c-title-jp {
		font-size: 16px;
		color: #543C30;

		&:before, &:after {
			border: 1px solid #543C30; } }

	.feature-image {
		box-shadow: -30px 30px 2px 5px #FFECC8;
		border-radius: 30px;
		width: 80%; }

	.feature-image-r {
		box-shadow: 30px 30px 2px 5px #FFECC8;
		border-radius: 30px;
		width: 80%; }

	.c-subtitle {
		font-size: 24px;
		font-weight: bold;
		line-height: 1.6; }

	.c-help {
		font-size: 16px; }

	.feature-tree1 {
		position: absolute;
		top: 15%;
		left: 5%;
		width: 5%; }

	.feature-tree2 {
		position: absolute;
		top: 35%;
		right: 5%;
		width: 3%; }

	.feature-tree3 {
		position: absolute;
		top: 60%;
		left: 5%;
		width: 3%; }

	.feature-tree4 {
		position: absolute;
		top: 75%;
		right: 5%;
		width: 8%; }

	.feature-couple1 {
		position: absolute;
		top: 17%;
		right: 5%;
		width: 6%; }

	.feature-couple2 {
		position: absolute;
		top: 37%;
		left: 2%;
		width: 10%; }

	.feature-couple3 {
		position: absolute;
		top: 60%;
		right: 5%;
		width: 6%; }

	.feature-deco2 {
		position: absolute;
		top: 80%;
		left: 3%;
		width: 10%; } }

/*********************** service *************************** */

.l-service {
	position: relative;
	background: url(../images/park/top/service/top_service_bg.png) top center;
	padding: 100px 0px 100px 0px;
	width: 100%;
	max-width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -10vh;
	min-height: 100vh;
	z-index: 2;

	#l-service-menu {
		width: 1150px;
		max-width: 100%;
		margin: 20px auto;
		padding: 20px; }

	.c-title {
		color: white;
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		font-weight: normal;
		letter-spacing: 5px;
		margin-bottom: 0px; }

	.c-title-jp {
		font-size: 16px;
		color: #FFE380;

		&:before, &:after {
			border: 1px solid #FFE380; } }

	.service-item {
		padding: 10px;
		text-align: center;
		cursor: pointer;
		background: url(../images/park/top/service/top_service_rect.png);
		background-size: 100% 100%;
		margin-bottom: 20px;

		&:hover {
			transform-origin: center;
			transform: scale(1.1);
			transition: all .3s; }

		.service-title {
			font-size: 15px;
			padding-top: 20px; }

		.service-icon {
			height: 30%;
			width: auto;
			padding-top: 20px; }

		.service-icon1 {
			width: 50%;
			height: auto;
			padding-top: 45px; } }

	.service-deco1 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 20%;
		z-index: 2;
		@media screen and (max-width: 767px) {
			width: 40%; } }

	.service-deco2 {
		position: absolute;
		top: 0;
		right: 0;
		width: 20%;
		z-index: 2;
		@media screen and (max-width: 767px) {
			width: 40%; } }

	.service-tree1 {
		position: absolute;
		top: 0;
		left: 10%;
		width: 12%;
		z-index: 2;
		@media screen and (max-width: 767px) {
			width: 24%; } } }

/*********************** clinic *************************** */

.l-clinic {
	position: relative;
	background: url(../images/park/top/clinic/top_clinic_bg.png) top center;
	padding: 100px 0px 100px 0px;
	width: 100%;
	max-width: 100%;
	background-size: cover;
	margin-top: -10vh;
	z-index: 0;
	overflow: hidden;

	.c-title {
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		color: #54A868;
		letter-spacing: 2px;
		margin-bottom: 0px; }

	.c-title-jp {
		font-size: 16px;
		color: #543C30;

		&:before, &:after {
			border: 1px solid #543C30; } }

	.item-title {
		font-size: 18px; }

	.item-help {
		font-size: 16px;
		padding-top: 10px; }

	.owl-carousel .item {
		background-color: white;
		border: 2px solid #54A868;
		border-radius: 14px; }

	.item {
		img {
			border-top-right-radius: 5px;
			border-top-left-radius: 5px; }

		.down-content {
			background-color: #fff;
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;

			h4 {
				font-size: 22px;
				text-transform: uppercase;
				color: #1e1e1e;
				letter-spacing: 0.5px;
				font-weight: 700;
				margin-top: 0px;
				margin-bottom: 20px; }

			p {
				margin-bottom: 25px; }

			img {
				width: 40px;
				border: 2px solid #f5a425;
				border-radius: 50%;
				text-align: left;
				display: inline-block; }

			.text-button-pay {
				float: right;
				display: inline-block;
				margin-top: -30px;

				a {
					color: #f5a425;
					font-size: 16px; } }

			.text-button-free {
				float: right;
				display: inline-block;
				margin-top: -30px;

				a {
					color: #7a7a7a;
					font-size: 13px; } } } }

	.owl-carousel {
		.owl-nav {
			display: none; }

		.owl-dots {
			text-align: center; }

		button {
			&.owl-dot {
				width: 16px;
				height: 16px;
				background-color: #ddd;
				margin: 50px 10px 0px 10px;
				outline: none;
				border-radius: 8px; }

			&.active {
				background-color: #543C30; } } } }

/*********************** clinic-table *************************** */

.l-clinic-table {
	position: relative;
	margin-top: -10vh;
	padding: 200px 0px;
	z-index: 0;

	table {
		width: 80%;
		margin: 0px auto;

		tr {
			border-bottom: 2px dotted #FF8266; }

		th {
			width: 15%;
			vertical-align: middle;
			text-align: center;
			padding: 10px 0px;
			font-size: 16px;
			font-weight: normal; }

		td {
			width: 85%;
			vertical-align: middle;
			padding: 10px 0px;
			font-size: 16px; }
		a {
			color: #212529; } }
	.clinic-grass1 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 35%;
		z-index: 2;
		// @media screen and (max-width: 767px)
 }		//ottom: -10%

	.clinic-grass2 {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 35%;
		z-index: 2;
		// @media screen and (max-width: 767px)
 }		//ottom: -10%

	.clinic-tree1 {
		position: absolute;
		top: 20%;
		right: 5%;
		width: 10%;
		@media screen and (max-width: 767px) {
			width: 20%;
			top: -8%; } }

	.clinic-tree2 {
		position: absolute;
		top: 20%;
		left: 5%;
		width: 10%;
		@media screen and (max-width: 767px) {
			width: 20%;
			top: -13%; } } }

/******************************* SNS ****************************** */

.l-sns {
	position: relative;
	background: url(../images/park/top/sns/top_sns_bg.png) top center;
	padding: 100px 0px 250px 0px;
	width: 100%;
	max-width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top: -15vh;

	.c-title {
		font-size: 20px;
		color: white;
		line-height: 40px;
		letter-spacing: 1px;
		font-family: 'Fredoka One', cursive;
		text-align: center; }
	.sns-couple1 {
		position: absolute;
		top: 30%;
		left: 7%;
		width: 10%; }

	.sns-couple2 {
		position: absolute;
		top: 30%;
		right: 7%;
		width: 10%; }

	.sns-couple3 {
		position: absolute;
		top: 82%;
		right: 15%;
		width: 10%; }

	.item-title {
		font-size: 18px; }

	.item-help {
		font-size: 16px; }

	.owl-carousel .item {
		background-color: white; }

	.item .down-content {
		background-color: #fff;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;

		h4 {
			font-size: 16px;
			text-transform: uppercase;
			color: #1e1e1e;
			letter-spacing: 0.5px;
			font-weight: 700;
			margin-top: 0px;
			margin-bottom: 5px; }

		p {
			margin-bottom: 5px;
			color: #888; }

		img {
			width: 40px;
			border: 2px solid #f5a425;
			border-radius: 50%;
			text-align: left;
			display: inline-block; }

		.text-button-pay {
			float: right;
			display: inline-block;
			margin-top: -30px;

			a {
				color: #f5a425;
				font-size: 13px; } }

		.text-button-free {
			float: right;
			display: inline-block;
			margin-top: -30px;

			a {
				color: #7a7a7a;
				font-size: 13px; } } }

	.owl-carousel {
		.owl-nav {
			display: none; }

		.owl-dots {
			text-align: center; }

		button {
			&.owl-dot {
				width: 16px;
				height: 16px;
				background-color: #ddd;
				margin: 50px 10px 0px 10px;
				outline: none;
				border-radius: 8px; }

			&.active {
				background-color: #543C30; } } }
	& + .l-footer-top,& + .l-staff-recruit {
		margin-top: -15vh; } }
.l-sns-insta {
	max-width: 820px;
	margin: 0 auto; }
/*********************** time-table *************************** */

.time-table {
	width: 100%;
	border-radius: 10px !important;
	text-align: center;
	border-collapse: separate !important;
	background-color: #8E653F;

	th, td {
		font-size: 16px;
		font-weight: normal;
		padding: 5px 10px;
		background-clip: padding-box; }

	thead {
		background-color: #E8DACD;

		th {
			color: #8E653F; } }

	tbody {
		background-color: white;

		td {
			color: #FF8266; } }

	.time-label {
		font-size: 13px;
		font-family: 'Fredoka One', cursive;
		color: #8E653F !important; } }

.time-help {
	padding: 0px 50px; }

/*********************** header-table *************************** */

.header-table {
	width: 100%;
	border-radius: 10px !important;
	text-align: center;
	border-collapse: separate !important;
	background-color: #8E653F;

	th, td {
		font-size: 12px;
		font-weight: normal;
		padding: 2px 4px; }

	thead {
		background-color: #E8DACD;

		th {
			color: #8E653F; } }

	tbody {
		background-color: white;

		td {
			color: #FF8266; } }

	.time-label {
		font-size: 10px;
		font-family: 'Fredoka One', cursive;
		color: #8E653F !important; } }

/*********************** footer-reserve *************************** */

.l-footer-reserve {
	position: relative;
	background: url(../images/park/footer/footer_bg.png) top center;
	padding: 100px 0px 60px 0px;
	width: 100%;
	max-width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	// margin-top: -18vh

	.bar {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: auto; }

	.contact {
		width: 950px;
		max-width: 100%;
		margin: 0px auto;
		padding: 30px 10px 50px 10px; } }

.l-calendar .contact {
	width: 950px;
	max-width: 100%;
	margin: 0px auto;
	padding: 30px 10px 50px 10px; }

.l-footer-reserve {
	.title {
		font-size: 32px;
		// letter-spacing: 2px
		color: white;
		font-weight: normal; }

	.sub-title {
		font-size: 20px;
		letter-spacing: 3px;
		color: white;
		font-weight: normal; }

	.dotted {
		border-bottom: 1px dotted white; }

	#map {
		width: 100%;
		height: 300px; }

	.contact .c-item {
		background-color: #77695F;
		width: 100%;
		padding: 10px 10%;
		border-radius: 12px;
		height: 250px; } }

.l-calendar .c-item {
	background-color: #77695F;
	width: 100%;
	padding: 10px 10%;
	border-radius: 12px;
	height: 250px;
	@media screen and (max-width: 767px) {
		padding: 10px 15px; } }
.l-footer-reserve .contact .c-item .c-item-title, .l-calendar .c-item .c-item-title {
	height: 50px;
	min-height: 50px;
	background: url('../images/park/footer/footer_item_rect.png') no-repeat;
	background-size: 100% 50px;
	text-align: center;
	padding-top: 14px;
	margin: 0px auto;
	font-weight: bold;
	display: block;
	max-width: 350px; }

.zero {
	color: #FFE380;
	font-size: 10px;
	margin-left: 40px;
	margin-bottom: 0px;
	margin-top: 20px; }

.l-footer-reserve .contact .c-item .phone, .l-calendar .c-item .phone {
	color: #FFE380;
	font-family: 'Fredoka One', cursive;
	letter-spacing: 1px;
	margin-top: 20px !important; }

.l-footer-reserve .contact .c-item .c-item-title h4, .l-calendar .c-item .c-item-title h4 {
	font-size: 20px;
	font-weight: bold;
	color: #543C30;
	letter-spacing: 1px; }

.l-footer-reserve .contact .c-item .c-time, .l-calendar .c-item .c-time {
	font-size: 16px;
	color: white;
	margin-bottom: 10px; }

.l-footer-reserve .contact .c-item .c-help, .l-calendar .c-item .c-help {
	font-size: 16px;
	color: white;
	margin-bottom: 10px;
	line-height: 1.8; }

.l-footer-reserve .contact .c-item .c-button-title, .l-calendar .c-item .c-button-title {
	color: white;
	// font-size: 13px
	// margin-top: -21%
 }	// margin-left: -15px

.c-item {
	.btn-newer,.btn-retest {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 30px 30px;
		min-height: 44px;
		padding-right: 10px;
		background-image: url(../images/park/icon_arrow.svg);
		background-size: 14px 14px;
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		transition: all .3s;
		margin-bottom: 10px;
		@media screen and (max-width: 767px) {
			min-height: 0;
			padding: 10px 15px 10px 10px;
			background-position: right 5px top 50%;
			background-size: 10px 10px; }
		&:hover {
			transform: scale(1.1); } }
	.btn-newer {
		background-color: #ff6666;
		box-shadow: 0 3px 0 #c03737;
		width: 100%;
		font-size: 13px;
		height: 45px;
		padding: 0 5px 0 0;
		letter-spacing: 1px; }
	.btn-retest {
		background-color: #f0c13a;
		box-shadow: 0 3px 0 #b88806;
		width: 100%;
		font-size: 13px;
		height: 45px;
		padding: 0 5px 0 0;
		letter-spacing: 1px; }

	.c-button-title {
		margin-bottom: 0;
		@media screen and (max-width: 480px) {
			font-size: 3.2vw; } }
	// .btn-newer, .btn-retest
	//osition: relative
	//ransform-origin: center

	// .btn-newer img, .btn-retest img
	//idth: 100%
 }	//eight: auto

.l-footer-reserve {
	.c-intro-title {
		font-size: 18px;
		color: white;
		font-weight: bold;
		line-height: 1.6; }

	.contact .c-rects {
		width: 80%;
		margin: 0px auto;

		.rect {
			border-radius: 5px;
			border: 2px solid #7a6c5c;
			border-bottom: 5px solid #7a6c5c !important;
			background-color: white;
			height: 50px; } }

	.help {
		font-size: 16px;
		color: white;
		font-weight: normal;
		margin-bottom: 0px;
		letter-spacing: 1px; }

	.phone {
		color: white;
		letter-spacing: 1px;
 } }		// font-weight: bold

.border-top-l {
	border-top-left-radius: 8px;
	-moz-border-radius-topleft: 8px;
	-webkit-border-top-left-radius: 8px; }

.border-top-r {
	border-top-right-radius: 8px; }

.border-bottom-l {
	border-bottom-left-radius: 8px; }

.border-bottom-r {
	border-bottom-right-radius: 8px; }

#footer-menu {
	width: 900px;
	max-width: 100%;
	margin: 0px auto;
	padding: 10px;
	text-align: center;

	li {
		position: relative;
		display: inline-block;
		line-height: 1;
		margin: 0px;
		padding: 0px 20px;
		border-right: 1px solid white;

		&:last-child {
			border: none !important; }

		a {
			color: white !important;

			&:hover {
				color: orangered !important; } } } }

/* section footer */

footer {
	position: relative;
	background-color: #826D62 !important;

	.left-image {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 27%;
		height: auto; }

	.right-image {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 27%;
		height: auto; } }

@media screen and (max-width: 767px) {
	@keyframes MoveLR {
		0%, 100% {
			transform: translateX(-50px); }

		50% {
			transform: translateX(800px); } }

	@keyframes MoveRL {
		0%, 100% {
			transform: translateX(800px); }

		50% {
			transform: translateX(-100px); } }

	@keyframes MoveRL1 {
		0%, 100% {
			transform: translateX(1200px); }

		50% {
			transform: translateX(-80px); } }

	.owl-carousel button.owl-dot {
		margin: 20px 3px !important;
		width: 10px !important;
		height: 10px !important; }

	.l-calendar .c-title, .l-greeting .c-title, .l-features .c-title, .l-service .c-title, .l-clinic .c-title {
		font-size: 32px; }

	.btn {
		width: 259px;
		height: 46px;
		background: url(../images/park/common/main_btn_sp.png);
		font-size: 16px;
		line-height: 32px; }

	.l-banner {
		margin-top: 70px;

		h2 {
			font-size: 32px; } }

	.main-banner .caption {
		left: 30%;
		top: 70%;
		transform: translate(-40%, -50%); }

	.l-news .l-news-time {
		padding: 10px !important; }

	#cloud1 {
		top: 35px;
		animation: MoveLR 50s linear infinite; }

	#cloud2 {
		top: 30px;
		animation: MoveRL 50s linear infinite; }

	#cloud3 {
		top: 70px;
		animation: MoveRL1 50s linear infinite; }

	.deco-top {
		top: 5%; }

	.deco-bottom img {
		width: 33% !important; }

	.main-banner .caption h6 {
		font-size: 18px;
		padding: 5px 20px;
		font-weight: bold;
		background-color: transparent; }

	/**************** corona  ************** */

	.l-corona {
		.c-title {
			font-size: 26px; }

		#l-corona-help {
			margin-top: -30px;

			.p-5 {
				padding: 10px !important; } }

		.c-subtitle {
			margin-left: 1%;
			width: 98%; } }

	/**************** news  ************** */

	.l-news {
		padding: 0 15px 150px 15px;
		background: url('../images/park/top/top_news_bg_sp.png') top center;

		#c-header-image {
			width: 85% !important; }

		.c-header-title {
			margin-top: -11vw;
			font-size: 22px; }

		.c-block-title {
			font-size: 22px;
			padding: 10px 0px;
			width: 40%;
			margin: 5px auto; }

		.c-subtitle {
			width: 75%;
			margin: 5px auto;
			font-size: 22px; }

		.news-bug1 {
			top: 10px; }

		.news-bug2 {
			top: -3px; } }

	/**************** calendar  ************** */

	.l-calendar {
		padding-bottom: 100px;

		#gg-calendar {
			width: 100%;
			height: 300px; } }

	rt {
		font-size: 9px; }

	/**************** greeting  ************** */

	#greeting {
		.pl-5 {
			padding-left: 12px !important; }

		.c-title-career {
			width: 40%;
			margin: 5px auto; }

		.photo-image {
			box-shadow: -10px 20px 2px 1px #FFECC8; } }

	/**************** features  ************** */

	.l-features {
		.btn {
			float: none; }

		.feature-image {
			box-shadow: -7px 7px 2px 2px #FFECC8;
			border-radius: 7px;
			margin-left: 10%;
			margin-bottom: 15px; }

		.feature-image-r {
			box-shadow: 7px 7px 2px 2px #FFECC8;
			border-radius: 7px;
			float: left !important;
			margin-left: 10% !important;
			margin-bottom: 15px; }

		.c-subtitle {
			padding: 10px;
			font-size: 20px; }

		.c-help {
			padding: 15px; }

		.point-image {
			padding-top: 40px; }

		.point-image-r {
			padding-top: 10px;
			margin-left: 25px; } }

	/**************** l-service  ************** */

	.l-service {
		background: url(../images/park/top/service/top_service_bg_sp.png) no-repeat;
		background-size: 150% 100%;
		padding: 50px 0px; }

	.l-clinic {
		background: url(../images/park/top/clinic/top_clinic_bg_sp.png) no-repeat;
		background-size: 100% 100%;
		margin-top: -120px;
		padding: 100px 30px; }

	.l-clinic-table {
		padding: 60px 10px;
		margin-bottom: 120px; }

	.time-help {
		padding: 30px; }

	.btn-scroll-top {
		transform-origin: bottom right;
		transform: scale(0.6); }

	/**************** l-clinic-table  ************** */

	.l-clinic-table table {
		width: 100%;

		th {
			width: 25%; }

		td {
			width: 75%; } }

	/**************** footer  ************** */

	.c-item {
		.c-item-title {
			margin-left: -10px; }

		.phone {
			font-size: 24px; }

		.btn-newer {
			// margin-left: -20px

			// .c-button-title
			//argin-top: -27% !important
			//argin-left: -30px !important
 }			//ont-size: 12px !important

		.btn-retest .c-button-title {
			// margin-top: -27% !important
			// margin-left: 0px !important
 }			// font-size: 12px !important

		.btn-newer img, .btn-retest img {
 } }			// width: 120% !important

	.zero {
		font-size: 8px; }

	.time-table {
		th, td {
			font-size: 13px;
			padding: 5px 5px; } }
	.l-footer-reserve {
		.title {
			font-size: 28px; } } }

@media screen and (min-width: 768px) and (max-width: 950px) {
	@keyframes MoveLR {
		0%, 100% {
			transform: translateX(-50px); }

		50% {
			transform: translateX(800px); } }

	@keyframes MoveRL {
		0%, 100% {
			transform: translateX(800px); }

		50% {
			transform: translateX(-100px); } }

	@keyframes MoveRL1 {
		0%, 100% {
			transform: translateX(1200px); }

		50% {
			transform: translateX(-80px); } }

	.btn {
		width: 259px;
		height: 46px;
		background: url(../images/park/common/main_btn_sp.png);
		font-size: 16px;
		line-height: 32px; }

	#cloud1 {
		top: 35px;
		animation: MoveLR 50s linear infinite; }

	#cloud2 {
		top: 30px;
		animation: MoveRL 50s linear infinite; }

	#cloud3 {
		top: 70px;
		animation: MoveRL1 50s linear infinite; }

	.deco-top {
		top: 5%; }

	.l-corona {
		.c-subtitle {
			margin-left: 1%;
			width: 98%; }

		#l-corona-help {
			margin-top: 0px; } }

	/**************** news  ************** */

	.l-news {
		.c-header-title {
			margin-top: -38px;
			font-size: 16px; }

		.c-subtitle {
			font-size: 16px; }

		.c-block-title {
			font-size: 16px;
			padding: 10px 5px; }

		.deco3, .deco4 {
			top: 80%; }

		.news-bug1 {
			top: -5px; }

		.news-bug2 {
			top: -15px; } }

	/**************** calendar  ************** */

	.l-calendar {
		.cal-bug1 {
			left: 5%;
			top: 40%; }

		.cal-bug2 {
			top: 42%;
			width: 0; }

		.cal-grass1 {
			top: 66%; }

		.cal-grass2 {
			top: 85%;
			width: 30%; }

		.cal-bird {
			width: 22%; } }

	/**************** greeting  ************** */

	#greeting .photo-image {
		box-shadow: -10px 20px 2px 1px #FFECC8; }

	.l-greeting {
		.greet-deco-1 {
			top: 10%;
			left: 2%;
			width: 24%; }

		.greet-deco-2 {
			position: absolute;
			bottom: 3%;
			right: -1%;
			width: 24%;
			z-index: 2; }

		.greet-child-2 {
			position: absolute;
			top: 65%;
			left: 15%;
			width: 15%; }

		.greet-child-1 {
			position: absolute;
			bottom: 5%;
			left: 5%;
			width: 22%; }

		.greet-tree-1 {
			position: absolute;
			top: 55%;
			left: 7%;
			width: 8%; }

		.greet-tree-2 {
			position: absolute;
			top: 18%;
			right: 8%;
			width: 5%; } }

	#greeting .c-title-career {
		padding: 10px 5px;
		font-size: 16px;
		text-align: left; }

	/**************** features  ************** */

	.l-features {
		.btn {
			float: left;
			margin-left: 80px; }

		.feature-image {
			box-shadow: -15px 15px 2px 5px #FFECC8;
			border-radius: 15px; }

		.feature-image-r {
			box-shadow: 15px 15px 2px 5px #FFECC8;
			border-radius: 15px; } }

	/**************** l-clinic-table  ************** */

	.l-clinic-table table {
		width: 100%;

		th {
			width: 25%; }

		td {
			width: 75%; } }

	/**************** SNS  ************** */

	.l-sns .c-title {
		font-size: 16px;
		margin-left: -10px; }

	.l-features .c-subtitle {
		font-size: 20px; }

	rt {
		font-size: 8px; }

	/**************** footer  ************** */

	.l-footer-reserve .contact .c-item .phone, .l-calendar .c-item .phone {
		font-size: 21px; }

	.c-item {
		.btn-newer .c-button-title, .btn-retest .c-button-title {
			// font-size: 11px !important
 } }			// margin-top: -23% !important

	.zero {
		font-size: 8px; }

	footer {
		.left-image, .right-image {
			width: 18%; } }

	.time-table {
		th, td {
			font-size: 13px;
			padding: 5px 5px; } } }

@media only screen and (-webkit-max-device-pixel-ratio: 2) {
	#cloud1, #cloud2, #cloud3 {
		width: 64px;
		height: 22px; } }

@media only screen and (-webkit-max-device-pixel-ratio: 3) {
	#cloud1, #cloud2, #cloud3 {
		width: 64px;
		height: 22px; } }

/* dots */

.top-left-dot {
	position: absolute;
	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #54A868; }

.top-right-dot {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #54A868; }

.bottom-left-dot {
	position: absolute;
	bottom: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #54A868; }

.bottom-right-dot {
	position: absolute;
	bottom: 6px;
	right: 6px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #54A868; }
