.l-main-access {
	position: relative;
	background: url(../images/park/access/access_bg_pc.png) top center no-repeat;
	padding: 220px 0px 20vh 0px;
	width: 100%;
	max-width: 100%;
	min-height: 100vh;
	background-size: 100% 18vh;
	margin-top: -18vh;

	.c-title {
		color: #54A868;
		font-size: 48px;
		font-family: 'Fredoka One', cursive;
		letter-spacing: 5px; }

	.c-help {
		font-size: 16px;
		line-height: 2; }

	.c-line {
		color: #FFE380; }

	#g-map {
		width: 100%;
		height: 550px; }

	#h-map {
		width: 550px;
		max-width: 100%;
		// height: 550px
		padding: 10px;
		margin: 20px auto 20px;

		img {
			border-radius: 20px; } }

	#l-access-detail {
		width: 950px;
		max-width: 100%;
		padding: 10px;
		margin: 0 auto;

		#c-logo {
			float: left;

			h2, img {
				float: left; } }

		.phone {
			font-size: 26px;
			font-family: 'Fredoka One', cursive;
			margin-top: 20px;
			line-height: 1.5em; }
		.c-icon-title {
			font-size: 22px;
			line-height: 2; }

		.c-subtitle {
			margin-top: 5px;
			font-size: 16px; }

		.phone i {
			color: #FF8266; }

		.text-item {
			border-top: 2px solid #FF8266;
			border-bottom: 2px solid #FF8266;
			padding: 20px 10px 0px;
			text-align: center;

			.c-title {
				color: #FF8266;
				font-size: 22px;
				margin-bottom: 20px;
				font-family: 'Fredoka One', 'Kosugi Maru', sans-serif; }

			.c-help {
				font-size: 16px; } }

		.image-item {
			border: 2px solid #54A868;
			border-radius: 10px;
			padding: 20px;

			.c-title {
				font-size: 16px;
				color: #000000; } } } }

.access-deco1 {
	position: absolute;
	top: 120px;
	left: 0;
	width: 35%; }

.access-deco2 {
	position: absolute;
	top: 120px;
	right: 0;
	width: 35%; }

@media screen and (max-width: 767px) {
	.l-main-access {
		background-image: url(../images/park/access/access_bg_sp.png);
		background-size: 100% 23vh;
		background-position: top center;
		// padding-bottom: 50px
		margin-top: -20vh;
		min-height: 75vh;
		padding-top: 180px;

		.c-title {
			font-size: 32px; }

		#g-map {
			height: 80vw; }

		#h-map {
 }			// height: 100vw
		.access-deco1 {
			top: 140px; }
		.access-deco2 {
			top: 140px; } } }
